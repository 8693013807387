.buttonGroup {
  display: flex;
  padding: 10px 16px;
  background-color: var(--bg-select);
  justify-content: space-between;
  border-radius: 50px;
  gap: 16px;
}

.button,
.selectedButton {
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 2px solid var(--bg-select);
}

.button {
  background-color: var(--text-placeholder);
  transition: all 0.3s ease-out;
  outline: 2px solid transparent;
}

.selectedButton {
  background-color: #354da1;
  outline: 1px solid #f8faf8;
  transition: all 0.3s ease-in;
}

.selectedButton:nth-child(2) {
  background-color: #4bc16e;
}

.selectedButton:nth-child(3) {
  background-color: #ee75df;
}

.button:hover {
  cursor: pointer;
  outline: 1px solid #f8faf8;
}
