.cardSlider {
  position: relative;
  padding: 25px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 426px;
  max-width: 740px;
  min-width: 600px;
  overflow: hidden;
}

.cardSlider::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/svg/comunityRanksBg.svg");
  background-repeat: no-repeat;
  z-index: 1;
}

.title {
  font-size: 20px;
}

.pagination {
  display: flex;
  gap: 8px;
  font-weight: 400;
  z-index: 2;
}

.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  margin-left: 1px;
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}
.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

.slider {
  width: 100%;
  height: 290px;
  margin-top: 10%; 
  transition: all 0.5s ease;
  overflow: visible;
}

.rank,
.rankOnFocus {
  display: flex;
  overflow: visible;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  border-radius: 50px 50px 0 0;
  transition: all 0.3s ease;
  font-weight: 600;
  background: #57575733;
  height: 290px;
  width: 100%;
}

.rankImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rankName {
  display: flex;
  justify-content: center;
  overflow: visible;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 14px;
}

.rank .rank img {
  border-radius: 100%;
}

.rankImage {
  width:  100%;
  height: 100%;
}

.users {
  font-weight: 500;
  display: flex;
  justify-content: center;
  gap: 14px;
  font-size: 14px;
  color: var(--text-primary);
}

@media screen and (min-width: 1200px) and (max-width: 1370px)  {
  .cardSlider {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 781px)  and (max-width: 1120px) {
  .cardSlider {
    margin-left: auto;
    margin-right: auto;
}
}

@media screen and (max-width: 640px) {
  .cardSlider {
    min-width: 250px;
  }
}

@media screen and (min-width: 540px) and (max-width: 650px) {
  .rankName {
    font-size: 12px;
  }
  .users {
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .rankName {
    font-size: 11px;
  }
  .users {
    font-size: 11px;
  }
  .cardSlider {
    min-height: 450px;
  }
} 

