.filterBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.filterBadge {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: var(--bg-input);
  border: 1px solid var(--card-border);
  color: var(--light);
  cursor: pointer;
  transition: all 0.5s ease;
}

.filterBadge:hover {
  background-color: var(--bg-global);
}

.filterBadgeName,
.filterBadgeContent {
  color: var(--light);
  font-size: 0.875rem;
  font-weight: 500;
}

.filterBadge > svg {
  color: var(--text-primary);
  transition: all 0.5s ease;
}

.filterBadge > svg:hover {
  color: var(--light);
}

.filterBadgeContent {
  color: var(--text-primary);
}
