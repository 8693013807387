.bonusAnalyticsContainer {
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.subHeading {
  margin-bottom: 40px;

}

.bonusAnalytics {
  background: transparent;
  padding: 0;
  border: none;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
