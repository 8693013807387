.sidebar,
.sidebarOpened,
.sidebarMobile {
  height: 100%;
  width: 250px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(126, 126, 126, 0.22);
  animation: fade 0.5s ease;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.212) -617.904px 0.454px / 599.849% 64.358%;
  float: left;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: -1;
  opacity: 0.5;
  background-image: url("../../../assets/images/sidebar-light.png");
  background-repeat: no-repeat;
}

.boxLogo {
  margin-top: 31px;
  overflow: hidden;
  flex-shrink: 0;
  transition: all 0.3s ease;
  text-align: center;
  margin-bottom: 10px;
}

.sidebar .avatar {
  height: 48px;
  width: 48px;
}
.sidebar .userName {
  visibility: hidden;
  opacity: 0;
  margin-bottom: 5px;
}

.firstStep,
.firstStepActive {
  margin-block: 20px 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 23px;
  border-radius: 8px;
  gap: 12px;
  color: var(--text-primary);
  transition: all 0.3s ease;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  cursor: pointer;
}

.firstStep:hover,
.firstStepActive,
.firstStepActive svg path {
  color: var(--text-selected);
  color: var(--text-selected);
}

.firstStep svg,
.firstStepActive svg {
  font-size: 20px;
  transition: all 0.3s ease;
}

.firstStep svg path {
  stroke: var(--text-primary);
  transition: all 0.3s ease;
}

.firstStep:hover svg path {
  stroke: var(--text-selected);
}

.firstStepActive svg path {
  stroke: var(--text-selected);
  transition: all 0.3s ease;
}

.boxNav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 2;
  overflow-y: auto;
  overflow: overlay;
  overflow-x: hidden;
  min-height: 100px;
  padding-left: 23px;
  scrollbar-color: var(--text-selected) transparent; /* Only Firefox */
  scrollbar-width: thin;
}
.boxNav::-webkit-scrollbar {
  width: thin; /* Chrome */
  background: transparent; /* Chrome */
}

.boxNav nav {
  margin-bottom: 25px;
}
.boxNav nav svg {
  font-size: 20px;
  transform: scaleX(-1);
}

.boxNav ul li a {
  display: flex;
  align-items: center;
  width: 100%;
}
.boxNav ul .element {
  cursor: pointer;
}
.boxNav ul .element,
.boxNav ul .elementActive {
  margin-bottom: 14px;
}

.boxNav ul .element div.mainCategory {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 12px 23px 12px 0;
  cursor: pointer;
  color: var(--text-placeholder);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.mainCategory,
.mainCategoryOpenCollapsed {
  cursor: pointer;
}

.boxNav ul .element div.mainCategoryOpenCollapsed {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 12px 23px 12px 0;
  border-radius: 8px;
}
.mainCategory .innerCategory,
.mainCategoryOpenCollapsed .innerCategory {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconWrapper {
  display: flex;
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.sidebar .iconWrapper {
  padding-left: 2.4px;
}

.boxNav ul li .icon svg {
  height: 20px;
  width: 20px;
  margin-right: 14px;
  color: var(--text-primary);
}
.boxNav ul li svg.arrow,
.boxNav ul li svg.arrowActive {
  color: var(--text-primary);
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-shrink: 0;
}
.boxNav ul li svg.arrowActive {
  transform: rotate(180deg);
}
.boxNav ul li svg path {
  stroke: var(--text-placeholder);
  transition: all 0.3s ease;
}

.sidebar .boxNav ul li svg.arrow {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.boxNav ul .elementActive div.mainCategory,
.boxNav ul .elementActive div.mainCategoryOpenCollapsed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 23px 12px 0;
  border-radius: 8px;
  color: var(--text-selected);
  /* background-color: var(--text-selected); */
  transition: all 0.3s ease;
}

.boxNav ul .elementActive .categoryText,
.boxNav ul .elementActive .arrow,
.boxNav ul .elementActive .mainCategoryOpenCollapsed .categoryText,
.boxNav ul .elementActive .mainCategoryOpenCollapsed .arrow {
  transition: all 0.3s ease;
  color: var(--text-selected);
}

.sidebar .boxNav ul .elementActive .categoryText,
.sidebar .boxNav ul .elementActive .arrow,
.sidebar .boxNav ul .elementActive .mainCategoryOpenCollapsed .categoryText,
.sidebar .boxNav ul .elementActive .mainCategoryOpenCollapsed .arrow {
  opacity: 0;
}

.boxNav ul .element .mainCategory:hover .categoryText,
.boxNav ul .element .mainCategory:hover .arrow,
.boxNav ul .element .mainCategoryOpenCollapsed:hover .categoryText,
.boxNav ul .element .mainCategoryOpenCollapsed:hover .arrow {
  transition: all 0.3s ease;
  color: var(--text-selected);
}

.boxNav ul .elementActive svg path,
.boxNav ul .elementActive .mainCategoryOpenCollapsed svg path {
  stroke: var(--text-selected);
}
.boxNav ul .element .mainCategory:hover svg path,
.boxNav ul .element .mainCategoryOpenCollapsed:hover svg path {
  transition: all 0.3s ease;
  stroke: var(--text-selected);
  color: var(--text-selected);
}
.boxNav ul .mainCategoryOpenCollapsed .categoryText,
.boxNav ul .mainCategoryOpenCollapsed .arrow {
  color: var(--text-primary);
}

.boxNav ul li .categoryText {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  opacity: 1;
  transition: all 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
}

.boxNav div.subCategory {
  position: relative;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
}

.boxNav div.subCategory .innerSub {
  margin-left: 32px;
}
.boxNav div.subCategory .innerSub div.innerElement,
.boxNav div.subCategory .innerSub div.innerElementActive {
  padding: 14px 23px 14px 0;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boxNav div.subCategory:hover div.innerElement,
.boxNav div.subCategory .innerSub div.innerElementActive {
  color: var(--text-selected);
}

ul.closeDropdown {
  opacity: 0;
  transition: all 0.3s ease;
  overflow: hidden;
}
.closeDropdown.active {
  opacity: 1;
  transition: all 0.3s ease;
  overflow: hidden;
  margin-bottom: 14px;
}

.containerBox {
  padding-inline: 23px;
  transition: all 0.5s ease;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 80px;
  display: none;
}

.containerBox svg {
  flex-shrink: 0;
  font-size: 20px;
  color: var(--text-placeholder);
  transition: all 0.3s ease;
}

.itemBottom {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
  transition: all 0.3s ease;
}

.itemBottom:first-child {
  margin-right: 30px;
}

.itemBottom:hover,
.itemBottom:hover svg {
  color: var(--text-selected);
  cursor: pointer;
}

.footerSidebar {
  padding: 15px 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-primary);
  font-weight: 400;
  font-size: 16px;
  transition: all 0.3s ease;
}
.footerSidebar:hover {
  cursor: pointer;
  color: var(--text-selected);
}

.footerSidebar svg {
  font-size: 20px;
}
.iconFooter,
.iconFooterFlex {
  display: flex;
  flex-shrink: 0;
  transition: all 0.5s ease;
}

.iconFooterFlex {
  transform: scaleX(-1);
}

.labelFooter {
  opacity: 1;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.sidebar .footerSidebar .labelFooter {
  opacity: 0;
  padding-left: 2.4px;
}

.lock {
  display: flex;
  transition: all 0.3s ease;
}

.lock svg path {
  color: var(--text-primary) !important;
  fill: inherit !important;
}

.mainCategory:hover .lock svg path {
  color: var(--text-selected) !important;
}

.sidebar .lock {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.category {
  color: var(--text-placeholder);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 11px;
  margin-top: 50px;
}

.categoryLine {
  opacity: 0.2;
  background: #fff;
  backdrop-filter: blur(4.5px);
  height: 1px;
  margin-bottom: 19px;
}

.avatar {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
  padding-inline: 23px;
  margin-top: 35px;
  display: none;
}

.picture {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #36363733;
  flex-shrink: 0;
  background-size: cover;
}

.username {
  color: var(--light);
  font-size: 14px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lock svg path {
  stroke: var(--text-primary) !important;
  opacity: 0.6 !important;
}

@media screen and (max-width: 1199px) {
  .sidebar,
  .sidebarOpened,
  .sidebarMobile {
    transform: translateX(0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .sidebarMobile {
    transform: translateX(-100%);
  }
  .boxLogo img {
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .containerBox {
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    justify-content: center;
  }
  .avatar {
    display: flex;
  }
}
