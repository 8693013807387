.hamburgerMenu {
	display: none;
	width: 30px;
	height: 20px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

.hamburgerMenu div {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #fff;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.hamburgerMenu div:nth-child(1) {
	top: 0px;
}

.hamburgerMenu div:nth-child(2),
.hamburgerMenu div:nth-child(3) {
	top: 9px;
}

.hamburgerMenu div:nth-child(4) {
	top: 18px;
}

.hamburgerMenu.open div:nth-child(1) {
	top: 9px;
	width: 0%;
	left: 50%;
}

.hamburgerMenu.open div:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.hamburgerMenu.open div:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.hamburgerMenu.open div:nth-child(4) {
	top: 9px;
	width: 0%;
	left: 50%;
}

@media screen and (max-width: 1199px) {
	.hamburgerMenu {
		display: block;
	}
}
