.error {
	text-align: center;
	color: var(--light);
}

.icon {
	min-height: 100px;
	max-height: 100px;
	animation: error 0.4s ease;
	margin-bottom: 10px;
}

@keyframes error {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.error h2 {
	max-width: 280px;
	margin-inline: auto;
	margin-bottom: 10px;
}

.error p {
	color: var(--text-primary);
	font-size: 16px;
	width: 95%;
	margin-bottom: 10px;
	margin-inline: auto;
}

.dataInfo {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	margin-inline: auto;
	margin-top: 40px;
}
.debugMode {
	text-align: center;
	margin-inline: auto;
}

.label {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 3px;
}

.message {
	font-size: 16px;
	margin-bottom: 3px;
}

.showHtml {
	max-height: 250px;
	overflow-y: scroll;
	overflow: overlay;
}

.show {
	color: var(--primary);
	margin-block: 5px 16px;
	font-size: 15px;
}

.show:hover {
	color: var(--primary-hover);
	cursor: pointer;
}

.error .button {
	margin-block: 20px 10px;
}
