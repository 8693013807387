.tooltip {
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  overflow: visible;
  position: relative;
  padding-top: 4px;
}

.tooltip .tooltipText {
  font-weight: 500;
  width: 250px;
  visibility: hidden;
  padding: 12px;
  border-radius: 8px;
  background: #474747;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  bottom: 20px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltipText,
.tooltip.active .tooltipText {
  visibility: visible;
  opacity: 1;
}

.link {
  color: #00a88b;
  font-weight: 700;
}
