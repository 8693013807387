.walletsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-gap: 22px;
  justify-content: space-between;
  align-items: center;
}

.walletItem {
  flex-grow: 1;
  height: 100%;
}

.walletItem > div {
  height: 100%;
}

@media (max-width: 768px ) {
  .walletsContainer {
    grid-template-columns: 1fr;
  }
}