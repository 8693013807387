.successForm {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.iconSuccess {
	height: 70px;
	width: 70px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	animation: var(--appearScale);
	margin-bottom: 15px;
}
.successForm h5 {
	text-align: center;
	max-width: 330px;
	margin-bottom: 20px;
}

.description {
	text-align: center;
	max-width: 420px;
	color: var(--auth-text-secondary);
	margin-block: -10px 20px;
}

.forgotLink {
	color: var(--auth-primary);
	text-decoration: unset;
}

.goBack {
	display: flex;
	justify-content: center;
	align-items: center;
}
.goBack > a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.goBackReverse > a {
	flex-direction: row-reverse;
}

.goBack > a > svg {
	width: 25px;
	height: 25px;
}

.goBack > a,
.goBack > svg > a {
	color: var(--auth-primary);
	font-size: 16px;
	text-decoration: unset;
	transition: all 0.5s ease;
	font-weight: 500;
}

.goBack > a:hover,
.goBack > a > svg {
	color: var(--auth-primary-hover);
}

.goBack > a:active,
.goBack > a > svg {
	scale: 1.02;
}
