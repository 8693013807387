.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.teamGrowthContainer {
  overflow: visible;
  padding: 30px 28px;

  display: flex;
  flex-direction: column;
}

.teamGrowth {
  background: transparent;
  padding: 0;
  border: none;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}


.titleContainer{
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
