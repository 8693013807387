.bonusHistory {
  padding: 33px 38px;
  overflow: hidden;
}

.rankColContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rankInfoImg {
  width: 44px;
  height: 44px;
}

.ipImg {
  width: 18px;
  height: 18px;
}

.paginationButton {
  background: transparent;
  color: var(--text-primary);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paginationButton:hover {
  background: transparent;
}

.toPageInput {
  border: 2px solid #5a5a5a;
  padding: 12px 15px;
  border-radius: 8px;
  width: 47px;
}

.toPageInput input {
  caret-color: white;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--light);
}

.pastReferral {
  overflow: visible;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

@media screen and (max-width: 300px) {
  .action {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
  }
  .bonusHistory {
    padding: 33px 15px;
  }
}
