.loader {
  width: calc(100% - 250px);
  height: calc(100% - var(--header-main));
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
}

.loaderFullscreen {
  width: 100%;
  height: 100%;
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.defaultLoaderImage {
  max-width: 150px;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderBox {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 0;
}
.rotate {
  animation: rotation 2s infinite linear;
}
.fixed {
  transform: translateX(+20px);
}

@media screen and (max-width: 1199px) {
  .loader {
    width: 100%;
  }
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
