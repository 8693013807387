.header {
  margin-block: 32px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title .parent {
  padding-right: 20px;
  border-width: 1px;
  font-weight: 700;
  font-size: var(--font-size-base);
  border-style: solid;
  border-top: none;
  border-left: none;
  border-bottom: none;
  color: var(--text-placeholder);
  border-image: linear-gradient(
      to top,
      rgba(68, 68, 68, 0) 0%,
      rgba(169, 169, 169, 0.6) 51.35%,
      rgba(68, 68, 68, 0) 100%
    )
    1 10%;
  height: 100%;
}

.title svg {
  margin-top: 3px;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.title .currentPage {
  font-size: var(--font-size-lg);
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--light);
  font-weight: 700;
}

.title svg:hover {
  color: var(--text-selected);
}
.rotating {
  animation: rotate 3s linear infinite;
}

@media screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
