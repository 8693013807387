.bonusHistory {
  padding: 33px 38px;
  overflow: unset;
}
.paginationButton {
  background: transparent;
  color: var(--text-primary);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paginationButton:hover {
  background: transparent;
}

.toPageInput {
  border: 2px solid #5a5a5a;
  padding: 12px 15px;
  border-radius: 8px;
  width: 47px;
}

.toPageInput input {
  caret-color: white;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--light);
}

.pastReferral {
  overflow: visible;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.CpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.userName img {
  border-radius: 50%;
}

@media screen and (max-width: 350px) {
  .bonusHistory {
    padding-inline: 15px;
  }
}
