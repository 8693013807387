.gradientHeader {
  position: relative;
  min-height: 125px;
}

.above {
  border-radius: 8px;
}

.above {
  width: calc(100% - 10px);
  padding: 39px 31px;
  margin-right: auto;
  z-index: 9;
}

.above:after {
  content: "";
  position: absolute;
  top: 10px;
  right: 0;
  width: calc(100% - 10px);
  height: 100%;
  border-radius: 8px;
  z-index: -1;
  background-image: url("../../../assets/images/gradientHeaderV2BackBG.png");
}

.title {
  font-size: var(--font-size-md);
  color: var(--light);
  font-weight: 600;
  margin-bottom: 14px;
}
.subtitle {
  font-size: 16px;
  color: var(--text-primary);
  margin-bottom: 32px;
  max-width: 630px;
}

.action {
  text-align: center;
  /* width: 175px; */
  max-width: fit-content;
  padding: 14px 26px;
  color: var(--text-blue);
  font-size: 15px;
  font-weight: 700;
  background-color: var(--light);
  border: 1px solid var(--light);
  border-radius: 8px;
  transition: all 0.3s ease-in;
}

.action:hover {
  background: transparent;
  color: var(--light);
  border: 1px solid var(--light);
}
