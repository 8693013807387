.percentageWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.percentage {
  border: 1px solid var(--card-border);
  color: var(--light);
}

.percentage,
.percentageActive {
  border-radius: 2px;
  padding: 8px 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s;
}

.percentage:hover,
.percentageActive {
  border: 1px solid var(--text-selected-number);
  color: var(--text-selected-number);
}

@media screen and (max-width: 576px) {
  .percentage,
  .percentageActive {
    padding: 5px 10px;
  }
  .percentageWrapper {
    justify-content: center;
  }
}
