.modalContentWithoutClose {
  padding: 10px 22px 22px;
  border: none;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
}

.modalTitle {
  text-align: center;
  margin-bottom: 32px;
}

.otherOptionsHeading {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.otherOptionsTitle {
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: #f8faf8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.otherOptionsTitle span {
  text-transform: capitalize;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--card-border);
  margin-top: 16px;
  margin-bottom: 28px;
}

.copySection {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 14px;
}

.copySection .left {
  flex: 55%;
}

.copySection .right {
  flex: 40%;
}

.modalAction,
.modalActionCopy {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 14px;
  position: relative;

  color: var(--light);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  background-color: rgba(83, 82, 82, 0.26);
  border-radius: 4px;
}

.modalActionCopy {
  background-color: #00a88b;
}

.modalActionCopy svg path {
  stroke: var(--light);
}

.copyTooltip {
  position: absolute;

  top: -70%;
  left: 25%;

  background-color: var(--dark);
  border-radius: 8px;
  padding: 5px 10px;
  color: var(--light);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.copyTooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--dark) transparent transparent transparent;
}

.show {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 575px) {
  .socialIcons {
    gap: 15px;
  }
  .copySection {
    flex-wrap: wrap;
  }
  .copySection .right,
  .copySection .left {
    flex: 100%;
  }
  .copyTooltip {
    left: 40%;
  }
}
