.animation {
  animation: var(--fadeInAndScrollUpForSteps);
  display: flex;
  flex-direction: column;
  height: 100%;
}
.childContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 40px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 25px;
}

.title {
  font-weight: 600;
  margin-bottom: 18px;
}

.subTitle {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 36px;
  color: var(--text-primary);
}

.buttonStyle {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}

.leftButton {
  padding: 16px 36px;
  background-color: var(--bg-select);
  border-radius: 4px;
}

.rightButton {
  padding: 16px 36px;
  border-radius: 4px;
}

@media screen and (max-width: 991px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 576px) {
  .grid {
    display: flex;
    flex-direction: column;
  }
  .buttonStyle {
    flex-direction: column;
    gap: 25px;
  }
}
