.header {
  margin-block: 32px 44px;
}

.header p {
  font-size: 14px;
  margin-top: 20px;
  color: var(--text-units);
  max-width: 700px;
}
