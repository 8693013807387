.section {
  margin-bottom: 24px;
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.wiki {
  margin-block: 32px 44px;
}

.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.headers:first-child {
  flex-grow: 1;
}

.wikiBtn {
  min-width: 190px;
  border-radius: 4px;
}
@media screen and (max-width: 991px) {
  .wikiBtn {
    margin: auto;
  }
}
