.bestPerformersItem {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.userInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.userInfo > img {
  border-radius: 50px;
}

.userName {
  color: var(--light);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 140px;
  word-break: break-all;
}

.userId {
  color: var(--text-small-label);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.userPerformanceValue {
  text-align: center;
}

.userPerformanceAction {
  display: flex;
  justify-content: flex-end;
}

.userPerformanceLink {
  color: var(--text-subtitle);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

@media (max-width: 566px) {
  .bestPerformersItem {
    grid-template-columns: 1fr 40px;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 20px;
  }

  .userPerformanceAction {
    justify-content: center;
    grid-column: 1/-1;
  }
}
