.card {
  border-radius: 8px;
  border: 1px solid var(--card-border);
  background-blend-mode: overlay, normal;
  padding: 30px 25px;
  overflow: visible;
  transition: all 0.3s ease;
  background: var(--bg-like-blur);
}

.loader,
.error {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error {
  color: var(--error);
  font-weight: 600;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
