.select {
    outline: none;
    position: relative;
    justify-content: space-between;
  }
  .selectClass {
    border: 1px solid #e7e7e7;
    padding: 11px 15px;
    min-width: 150px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 12px;
    background-color: white;
  }
  .selectClassPlaceholder {
    color: grey;
  }
  .selected {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 4;
  }
  .selected > span {
    display: flex;
    align-items: center;
  }
  .selected .arrow {
    margin-right: 0;
  }
  .selected .arrowOpened {
    transform: rotate(180deg);
    margin-right: 0;
  }
  .searchInput{
    border: none;
    outline: none;
  }
  
  .options > div,
  .optionsOpened > div,
  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .options > div svg,
  .optionsOpened > div svg,
  .select svg {
    font-size: 20px;
    color: grey;
  }
  
  .selectClassOpened {
    border-width: 1px;
    border-style: solid;
    border-color: #e7e7e7;
    background-color: white;
    width: calc(100% + 1.9px);
    left: -0.95px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    scrollbar-color: transparent transparent; /* Only Firefox */
  }
  
  .selectClassOpened::-webkit-scrollbar {
    width: 0px; /* Chrome */
    background: transparent; /* Chrome */
  }
  
  .selectClassOption {
    padding: 12px 15px;
  }
  
  .selectClassOption:hover {
    background-color: rgba(128, 128, 128, 0.226);
  }