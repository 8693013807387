.card {
  padding: 41px 34px 49px;
}

.view {
  margin-bottom: 100px;
}

.warning {
  color: var(--text-units);
}

.warning span {
  font-weight: 700;
  letter-spacing: 1.12px;
  color: var(--light);
}

.stepsGrid {
  display: grid;
  grid-gap: 40px;
  align-items: flex-start;
  grid-template-columns: auto 1fr;
}

.containerWithButton {
  position: relative;
}

@media screen and (max-width: 991px) {
  .stepsGrid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 567px) {
  .disclaimer {
    flex-direction: column;
  }
}
