.title {
  font-size: var(--font-size-md);
  font-weight: 600;
  color: var(--light);
  margin-bottom: 24px;
}

.subTitle {
  color: var(--text-primary);
  font-size: 14px;
}