.bonusAnalyticsContainer {
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}
.subHeading {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.borderlessCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.borderlessCard h6 {
  font-weight: 400;
}

.bonusAnalytics {
  background: transparent;
  padding: 0;
  border: none;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
@media screen and (max-width: 576px) {
  .subHeading {
    flex-direction: column;
  }
  .filter {
    margin: auto;
  }
  .borderlessCard {
    flex-direction: column;
    gap: 10px;
  }
}
