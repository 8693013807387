.exports {
  padding: 33px 38px;
  overflow: unset;
}
.paginationButton {
  background: transparent;
  color: var(--text-primary);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paginationButton:hover {
  background: transparent;
}

.toPageInput {
  border: 2px solid #5a5a5a;
  padding: 12px 15px;
  border-radius: 8px;
  width: 47px;
}

.toPageInput input {
  caret-color: white;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--light);
}

.pastReferral {
  overflow: visible;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ////////////////////// */
/** filters*/

.extraFilters {
  height: 100%;
  color: var(--light);
  margin-bottom: 24px;
  overflow: visible;
}

.heading {
  margin-bottom: 25px;
  color: var(--light);
}

.selectLabel {
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 500;
}

.applyButton {
  max-width: 180px;
}

.extraFiltersBody {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 20px;
}

.extraFiltersBody > div {
  flex: 23%;
  flex-grow: 0;
}

.extraFiltersActions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.datePicker {
  width: 100%;
}
.datePicker > div {
  padding: 12px;
  border-radius: 8px;
}
.applyFilterBtn {
  margin-top: 18px;
}

@media screen and (max-width: 991px) {
  .extraFiltersBody {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 575px) {
  .filterHeader {
    flex-direction: column;
  }
  .extraFiltersBody {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 350px) {
  .exports,
  .extraFilters {
    padding-inline: 15px;
  }
}
