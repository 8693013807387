@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
@import "react-calendar/dist/Calendar.css";
@import "react-international-phone/style.css";

:root {
  /* Colors */
  --light: #ffffff;
  --dark: #000000;

  --error: #fd3d3d;
  --success: #00d27b;
  --warning: #efc11e;
  --warning-dark: #de9731;

  /* new text color sea salt */
  --text-primary: #f8faf8;
  --light-Grey: #e4e4e4;
  --text-selected: #07f9c8;
  --text-selected-number: #4fc2ab;
  --text-placeholder: #9a9a9a;
  --text-completed: #72fec3;
  --text-In-progress: #ffb359;
  --text-Incomplete: #4d4d4d;
  --text-small-label: #a7a7a7;
  --text-units: #f1f1f1e5;
  --text-blue: #185767;
  --text-big-percentage: #6d6d6d;
  --text-qualified: #78cd50;
  --text-disqualified: #ff7878;

  --bg-primary: #111111;
  --bg-like-blur: #181818;
  --bg-like-blur-2: rgba(0, 0, 0, 0.212) -617.904px 0.454px / 599.849% 64.358%;
  --bg-select: #212121;
  --bg-modal: #1f1f1f;

  --card-border: #3d3d3d83;

  --overlay-modal: rgba(0, 0, 0, 0.35);

  --list-line: #656565;

  --bg-timeline-line: #329f4c;

  --bonus-chart: #1bd895;
  /* Layouts */
  --header-main: 80px;

  /* Animation */
  --fadeIn: fadeIn 0.5s ease;
  --fadeInAndScrollUp: fadeInAndScrollUp 0.5s ease;
  --fadeInAndScrollUpForSteps: fadeInAndScrollUpForSteps 1s ease;
  --appearScale: animationAppear 0.5s ease;

  /* Button*/
  --button: #3eab95;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #4d4d4dd5;
  width: 20px;
  border-radius: 24px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: var(--text-selected);
  border-radius: 24px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #849aac #849aac27;
}

html {
  scroll-behavior: smooth;
  overflow: hidden;
}

body {
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  scroll-behavior: smooth;
  font-size: var(--font-size-base);
  font-family: "Montserrat", sans-serif;
  color: var(--text-primary);

  /* todo: test mobile safe area  content="viewport-fit=cover"  */
  /* padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
		env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px); */
}

h1 {
  font-size: var(--font-size-xxxl);
}

h2 {
  font-size: var(--font-size-xxl);
}

h3 {
  font-size: var(--font-size-xl);
}

h4 {
  font-size: var(--font-size-lg);
}

h5 {
  font-size: var(--font-size-md);
}
h6 {
  font-size: var(--font-size-base);
}

input::placeholder {
  font-size: 14px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.row_none {
  display: none;
  background-color: white;
}
.row_none_show {
  display: block;
  background-color: white;
}
.row_small {
  display: flex;
  height: 80px;
  margin-top: 20px;
}
/* react flow */

.react-flow__edge.animated path {
  animation: none !important;
}
.react-flow {
  backdrop-filter: blur(100px);
  /* border: 1px solid rgba(80, 80, 80, 0.6); */
  border-radius: 8px;
}
.react-flow__controls-button {
  background: #ffffff56 !important;
  border-bottom: 1px solid #1e201e !important;
}
.react-flow__controls-button svg {
  stroke: #ffffff !important;
  fill: #ffffff !important;
}
.react-flow__controls-button:hover {
  background: #1bd895 !important;
}

/* Fluid font size variables, for browsers that support clamp */
@supports (font-size: clamp(1rem, 1vw, 1rem)) {
  :root {
    --font-size-sm: clamp(0.7rem, 0.1vw + 0.68rem, 0.8rem);
    --font-size-base: clamp(0.88rem, 0.19vw + 0.84rem, 1.06rem);
    --font-size-md: clamp(1.09rem, 0.32vw + 1.03rem, 1.42rem);
    --font-size-lg: clamp(1.37rem, 0.52vw + 1.26rem, 1.89rem);
    --font-size-xl: clamp(1.71rem, 0.81vw + 1.55rem, 2.52rem);
    --font-size-xxl: clamp(2.14rem, 1.22vw + 1.89rem, 3.35rem);
    --font-size-xxxl: clamp(2.67rem, 1.8vw + 2.31rem, 4.47rem);
  }
}

/* Fallback variables for browsers that don't support clamp */
@supports not (font-size: clamp(1rem, 1vw, 1rem)) {
  :root {
    --font-size-sm: 0.7rem;
    --font-size-base: 0.88rem;
    --font-size-md: 1.09rem;
    --font-size-lg: 1.37rem;
    --font-size-xl: 1.71rem;
    --font-size-xxl: 2.14rem;
    --font-size-xxxl: 2.67rem;
  }
  @media screen and (min-width: 1920px) {
    :root {
      --font-size-sm: 0.8rem;
      --font-size-base: 1.06rem;
      --font-size-md: 1.42rem;
      --font-size-lg: 1.89rem;
      --font-size-xl: 2.52rem;
      --font-size-xxl: 3.35rem;
      --font-size-xxxl: 4.47rem;
    }
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInAndScrollUp {
  0% {
    /* opacity: 0; */
    transform: translateY(15px);
  }
  100% {
    /* opacity: 1; */
    transform: translateY(0);
  }
}
@keyframes trigger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInAndScrollUpForSteps {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animationAppear {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: var(--text-selected);
}

/* phone input */
.react-international-phone-input-container {
  height: 52.6px !important;
  margin-top: 10px !important;
  background: transparent !important;
  border: 1px solid var(--card-border) !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  position: relative !important;
}

.react-international-phone-country-selector,
.react-international-phone-country-selector-button,
.react-international-phone-input {
  height: 100% !important;
  background: transparent !important;
}

.react-international-phone-country-selector {
  border-right: 1px solid var(--card-border) !important;
  position: initial !important;
}

.react-international-phone-country-selector-button {
  border: none !important;
  padding-inline: 10px !important;
}

.react-international-phone-input {
  border: none !important;
  width: 100% !important;
  caret-color: var(--primary) !important;
  color: var(--light) !important;
}

.react-international-phone-country-selector-dropdown {
  width: 100% !important;
  outline: none !important;
  background-color: var(--bg-select) !important;
  scrollbar-color: var(--text-primary) transparent !important;
}

.react-international-phone-country-selector-dropdown__list-item {
  font-size: 20px !important;
  color: var(--light) !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover,
.react-international-phone-country-selector-dropdown__list-item--focused {
  background: var(--bg-like-blur) !important;
}
