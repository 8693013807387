.box {
  padding: 26px 0;
  margin-top: 34px;
  display: grid;
  grid-template-columns: 1fr 30px 1fr;
  position: relative;
}

.shapeOne {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/ellipse-currentrank.png");
  background-repeat: no-repeat;
  background-size: 300px;
  z-index: -1;
  opacity: 0.2;
}
.shapeTwo {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/lights.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.2;
}

.border {
  width: 1px;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-image: linear-gradient(
      to bottom,
      rgba(131, 129, 129, 0) 0%,
      rgba(131, 129, 129, 0.4) 43.75%,
      rgba(108, 108, 108, 0) 100%
    )
    1 10%;
}

/* _LevelRank */
.levelCard {
  padding: 0 26px;
  display: grid;
  grid-template-columns: 1fr 210px;
  column-gap: 10px;
  align-items: center;
}

.text {
  position: relative;
  z-index: 3;
}

.level {
  color: #efefef;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.rank {
  padding-bottom: 38px;
  font-size: 700;
}

.rankDate {
  background: rgba(255, 255, 255, 0.15);
  padding: 8px;
  text-align: center;
  border-radius: 50px;
  font-size: 12px;
  max-width: 140px;
}
.nodate {
  height: 31px;
}

.coin {
  position: relative;
  max-height: 128px;
}

.coin img {
  width: 128px;
  height: 128px;
  position: relative;
  z-index: 1;
}

.opacityIcon,
.opacityIconLeft {
  width: 69px;
  position: absolute;
  bottom: 5px;
  left: -32px;
  filter: blur(2px);
  z-index: 0;
}

.opacityIconLeft {
  width: 88px;
  left: 79px;
}

.opacityIcon img,
.opacityIconLeft img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 991px) {
  .box {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
  .border {
    width: 100%;
    height: 1px;
    border-width: 1px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-image: linear-gradient(
        to left,
        rgba(131, 129, 129, 0) 0%,
        rgba(131, 129, 129, 0.4) 43.75%,
        rgba(108, 108, 108, 0) 100%
      )
      1 10%;
  }
  .levelCard {
    grid-template-columns: 1fr 160px;
  }
}

@media screen and (max-width: 575px) {
  .levelCard {
    padding: 0 26px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    text-align: center;
  }
  .opacityIcon,
  .opacityIconLeft {
    display: none;
  }
  .rankDate {
    margin-inline: auto;
    margin-bottom: 10px;
    margin-top: -20px;
  }
  .nodate {
    display: none;
  }
}
