.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
}
.button {
  border-radius: 30px;
  background: linear-gradient(
    106deg,
    rgba(221, 221, 221, 0.09) 0%,
    rgba(226, 226, 226, 0.02) 100%
  );
  border-radius: 30px;
  padding: 12px 16px;
}

.button:hover {
  cursor: pointer;
  background: linear-gradient(
    106deg,
    rgba(221, 221, 221, 0.18) 0%,
    rgba(226, 226, 226, 0.04) 100%
  );
}

.title {
  font-weight: 600;
}
.chartContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 30px;
  min-height: 218px;
}
.labelsFlex {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.labelsFlexInner {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.labelWrapper {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
}

.label span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.amount {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;

  color: white;
}

.amount span {
  font-size: 14px;
  font-weight: 400;
  color: #f1f1f1e5;
}

.bodyWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block: 10px 20px;
}

.labelCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.chart {
  margin-top: -30px;
}

.buttomWrapper {
  margin-top: 120px;
}

.separator {
  background-color: var(--card-border);
  width: 100%;
  margin: auto;
  height: 1px;
  margin-top: 35px;
}

.statusWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.status {
  margin-block: 20px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
  display: block;
}

.status span {
  font-size: 15px;
  font-weight: 600;
}

.success {
  color: var(--success);
}

.error {
  color: var(--warning);
}

.qualified {
  height: calc(100% - 100px);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  filter: brightness(1.1);
}

.qualified img {
  width: 45px;
}

.lineunlocked {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
  display: block;
}

.lineunlocked span {
  font-size: 16px;
  font-weight: 600;
  color: var(--light);
}

@media screen and (max-width: 1399px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 575px) {
  .titleWrapper {
    flex-direction: column;
    gap: 20px;
  }

  .chartContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 40px;
  }

  .chart {
    margin-top: 0;
  }

  .buttomWrapper {
    margin-top: auto;
  }

  .statusWrapper {
    flex-direction: column;
    justify-content: center;
  }

  .status,
  .lineunlocked {
    margin-left: 0;
    text-align: center;
  }

  .labelsFlexInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
