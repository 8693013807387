.userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-block: 26px;
}

.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.username {
  font-size: var(--font-size-md);
  color: var(--light);
  font-weight: 600;
  margin-bottom: 4px;
  word-break: break-all;
}

.copyBtn:hover,
.copyBtn:hover svg path {
  stroke: var(--text-selected-number);
  transition: all 0.3s ease;
}

@media screen and (max-width: 576px) {
  .userInfo {
    justify-content: center;
    flex-direction: column;
  }
}
