.dateRangePicker > div {
  border: 1px solid var(--border-input);
  background: var(--bg-select);
  color: var(--light);
  padding: 7px 15px;
  min-width: 150px;
  border-radius: 3px;
  font-size: 12px;
}

.dateRangePicker > span > div {
  z-index: 10;
}

.dateRangePicker input {
  background: var(--bg-select);
}

.dateRangePicker svg {
  stroke: var(--text-primary);
}

@media screen and (max-width: 320px) {
  .dateRangePicker > div {
    padding-inline: 10px;
  }
}
