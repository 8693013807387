.spinner {
  animation: rotation 2s infinite linear;

  width: 24px;
  height: 24px;
  background-image: url(../../../assets/svg/node-loading.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
