.bg {
  background-image: url("../../../assets/images/bg-layout2.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: top;
}
.layout {
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
