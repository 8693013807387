.uniLevelDepth {
  padding: 33px 38px;
}
.pagination {
  display: none;
}

.status {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.StatusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
