.termsModal {
  text-align: center;
}

.title {
  margin-inline: auto;
  max-width: 330px;
  margin-bottom: 15px;
}

.info {
  text-align: center;
  padding-inline: 10px;
  max-width: 420px;
  margin-inline: auto;
}
.info a {
  color: var(--text-selected);
}

.info a:hover {
  color: var(--text-selected-number);
  text-decoration: underline;
}

.termsAccept {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-block: 55px 10px;
  color: var(--text-primary);
  width: fit-content;
  margin-inline: auto;
}

.closeButton {
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
  max-width: 300px;
}

.pop {
  margin-bottom: 30px;
}

.infoDisclamer {
  text-align: left;
  padding-inline: 5px;
  max-width: 480px;
  margin-inline: auto;
  margin-bottom: 15px;
}

.infoDisclamer span {
  font-size: 14px;
  display: block;
  margin-top: 3px;
}

.supportLink {
  text-decoration: underline;
  color: var(--text-selected);
}

.images {
  width: 300px;
  margin-bottom: 20px;
}
