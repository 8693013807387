.closeButton {
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
  max-width: 300px;
}

.pop {
  margin-top: 30px;
}

.showAgain{
  margin-bottom: 10px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}