.section {
  margin-bottom: 25px;
}


.window {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  text-align: center;
  max-width: 360px;
}

.box p {
  margin-block: 20px 25px;
}
