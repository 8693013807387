.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  gap: 26px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 30px 0px;
}
.card {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.normalBar {
  position: absolute;
  width: 1px;
  height: 76px;
  background-color: var(--list-line);
  margin-bottom: 20px;
  right: 20%;
  opacity: 0.5;
}

.card:last-child .normalBar {
  display: none;
}

.normalBar:last-child {
  display: none;
}

.infoWrapper {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.infoDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.infoDetails p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}
.card:hover .neonBar {
  box-shadow: 0 0 12px var(--text-selected);
  background-color: var(--text-selected);
  color: var(--text-selected);
  transition: all 0.3s ease;
}

.card:hover .infoDetails,
.card:hover .infoDetails p {
  color: var(--text-selected);
  transition: all 0.3s ease;
}

@media screen and (max-width: 1320px) {
  .normalBar {
    right: 8%;
  }
}

@media screen and (max-width: 991px) {
  .gridWrapper {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  }
  .normalBar {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .gridWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
