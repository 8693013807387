.generalCard {
  position: relative;
  padding: 0;
  border: 0;
  background: none;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.addBackground {
  background: url(../../../../../assets/svg/waveOne.svg) no-repeat,
    var(--bg-like-blur);
  background-size: cover;
  padding: 24px 24px;
}

.label,
.labelBig {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 14px;
}

.labelBig {
  font-size: 16px;
  margin-bottom: 0px;
}

.bodyWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.bodyWrapperSmall {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}

.amount {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;
  margin-bottom: 28px;
}

.amountImg {
  position: relative;
  cursor: pointer;
}

.depthWrapper {
  font-size: var(--font-size-md);
  font-weight: 600;
  margin-bottom: 28px;
  position: relative;
}

.toolTipFullNumber {
  max-width: 150px;
  visibility: hidden;
  padding: 8px;
  border-radius: 8px;
  background: #474747;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  font-size: 13px;
  opacity: 0;
  transition: opacity 0.5s;
  white-space: nowrap;
}

.amount:hover .toolTipFullNumber,
.amount.showFullNumber .toolTipFullNumber {
  visibility: visible;
  opacity: 1;
}

.amountImg:hover .toolTipFullNumber,
.amountImg.showFullNumber .toolTipFullNumber {
  visibility: visible;
  opacity: 1;
  transform: translateX(-80px)
}

.amount span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.percentage {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.percentage span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-units);
}

@media screen and (max-width: 575px) {
  .container {
    grid-template-columns: 1fr;
  }
}