.card {
  padding: 0;
  background: none;
  border: none;
}

.sectionGrid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 25px;
}

.leftPart {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightPart {
  display: grid;
  gap: 20px;
}

/* ----------------------- CurrentRankCard ---------------------------- */

.currentRankCard {
  display: flex;
  justify-content: space-between;
}

.rankInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.rankInfo h6 {
  margin-bottom: 35px;
}
.rankInfo h5 {
  color: var(--text-selected-number);
}

.rankDate {
  font-size: 12px;
  color: var(--text-placeholder);
  margin-bottom: 40px;
}

.nextRankParagraph,
.nextRankParagraphMobile {
  font-size: 12px;
  margin-bottom: 30px;
}
.nextRankParagraphMobile {
  display: none;
}

.nextRankParagraph span .nextRankParagraphMobile span {
  color: #13ef95;
  font-size: 16px;
  font-weight: 600;
}

.rankImg {
  max-width: 135px;
  position: relative;
}

.rankImgFade {
  position: absolute;
  top: 0px;
  right: 50px;
  width: 130px;
  height: 130px;
  background-image: url(../../../../../assets/images/dummy-rank.png);
  background-size: cover;
  filter: blur(15px);
  opacity: 0.5;
  z-index: 0;
}

.rankImg img {
  width: 100%;
  position: relative;
  z-index: 2;
}

/* ----------------------- MonthlyCommissionRank ---------------------------- */

.monthlyCommissionRank {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 20px 30px;
}

.monthlyCommissionRank h6,
.toolTipTittle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.select {
  border: none;
  padding: 10px 16px;
  min-width: 90px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: var(--font-size-sm);
  border-radius: 6px;
  color: var(--text-primary);
  transition: all 0.4s ease;
  background: var(--bg-select);
}

.monthlyCommissionRankCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monthlyCommissionRankInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.monthlyCommissionRankImg {
  max-width: 135px;
  min-height: 197px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.monthlyCommissionRankImg img {
  width: 100%;
  position: relative;
  z-index: 2;
}
.monthlyCommissionRankImg span {
  font-size: 14px;
}
.monthlyCommissionRankGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 10px;
}

.monthlyCommissionRankItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  animation: var(--fadeInAndScrollUpForSteps);
}
.monthlyCommissionRankItem img {
  width: 40px;
  height: 40px;
}
.monthlyCommissionRankItem span {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}
.currentMonth {
  border: 1px solid var(--text-selected);
  padding: 2px;
  border-radius: 50%;
}

/* ----------------------- UsersCardGrid ---------------------------- */

.usersCardGrid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  justify-items: start;
  gap: 25px;
}

.usersCardGridFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;
  width: 100%;
}

.cardChildren {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.singleUsersCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.singleUsersCard P {
  color: var(--text-primary);
  font-size: 14px;
}

.amountWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.amountTop {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.amountTop span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.usersTarget {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 14px;
  color: var(--text-primary);
}

.usersTarget span {
  font-size: 12px;
  font-weight: 400;
}

.chart {
  margin-top: -40px;
}

.horizontalSeparator {
  width: 80%;
  height: 1px;
  margin: auto;
  background: var(--card-border);
}

.verticalSeparator,
.verticalSeparatorDown {
  height: 100%;
  min-height: 85px;
  width: 1px;
  margin: auto;

  background: var(--card-border);
}

.cardsInfoGrid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  justify-items: start;
  align-items: center;
  gap: 25px;
}

.cardBody {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  position: relative;
  gap: 10px;
}

.titleWrapper h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
}

.bodyWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bodyWrapper span {
  font-size: var(--font-size-md);
}

.amount {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.amount span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.order {
  font-size: 10px;
}

@media screen and (max-width: 1399px) {
  .verticalSeparatorDown {
    display: none;
  }

  .sectionGrid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .usersCardGrid,
  .cardsInfoGrid {
    grid-template-columns: 1fr;
  }

  .cardsInfoGrid {
    justify-items: center;
  }
  .monthlyCommissionRankCard {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .verticalSeparator {
    width: 100%;
    height: 1px;
    min-height: auto;
    background: var(--card-border);
  }
}

@media screen and (max-width: 575px) {
  .currentRankCard {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
  }

  .rankInfo {
    align-items: center;
  }
  .nextRankParagraphMobile {
    display: block;
  }
  .nextRankParagraph {
    display: none;
  }

  .monthlyCommissionRank {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 400px) {
  .cardChildren {
    flex-direction: column;
    gap: 20px;
  }

  .chart {
    margin-top: 0;
  }

  .usersCardGridFlex {
    row-gap: 20px;
  }
}

@media screen and (max-width: 320px) {
  .monthlyCommissionRankGrid {
    column-gap: 15px;
  }
}
