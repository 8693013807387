.section {
  margin-bottom: 25px;
}
.statisticsAnalytics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

@media screen and (max-width: 1199px) {
  .statisticsAnalytics {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
