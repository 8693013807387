.bg {
  background-color: #181818;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: top;
}
.layout {
  width: 100%;
  overflow: scroll;
  position: relative;
  height: 100vh;
  margin-top: 16px;
}

.bgNone {
  background: none;
  padding: 0 20px;
}
