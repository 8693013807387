.container {
  padding: 30px 0 0;
  overflow: hidden;
}

.header {
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  z-index: 2;
}

.card {
  margin-left: 42%;
  transition: all 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card,
.pulseAnimatioCardOpacity {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  height: 430px;
  position: relative;
}

.selected .selectedLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 29px;
}

.hidden .selectedLayer {
  transform: translateY(3px);
}

.hidden {
  width: 290px;
  height: 174px;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  transition: height 0.5s;
}

.selected {
  width: 290px;
  height: 230px;
  text-align: center;
  position: relative;
  margin-top: 200px;
  transition: height 0.5s;
  flex-shrink: 0;
}

.boxText,
.boxTextNext {
  height: calc(100% - 29px);
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(39, 50, 55, 0.38) 0%, #4c9d71 100%);
  font-weight: 700;
  padding-inline: 20px;
  overflow: hidden;
}

.boxTextNext {
  background: linear-gradient(
      180deg,
      rgba(39, 50, 55, 0.12) 0%,
      #3e444100 65.25%
    ),
    url("../../../../../assets/images/rank-form-next.png");
  color: #6e6d6d;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.rankNumber {
  font-size: 14px;
  font-weight: 600;
  margin-block: 25px 8px;
  position: relative;
  z-index: 1;
}

.name {
  padding-bottom: 19px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.selected .name {
  font-weight: 700;
}

.divider {
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-top: none;
  border-image: linear-gradient(
      to left,
      rgba(68, 68, 68, 0) 0%,
      rgba(169, 169, 169, 0.4) 51.35%,
      rgba(68, 68, 68, 0) 100%
    )
    1 10%;
  position: relative;
  z-index: 1;
}

.date {
  font-size: 12px;
  font-weight: 500;
  margin-block: 16px 26px;
  position: relative;
  z-index: 1;
}

.currently {
  font-size: 12px;
  font-weight: 700;
  padding: 10px;
  border-radius: 20px;
  background: #5d9779;
  max-width: 100px;
  margin-inline: auto;
  position: relative;
  z-index: 1;
}

.rankRules {
  font-size: 10px;
  position: absolute;
  left: 50%;
  bottom: 22px;
  transform: translateX(-50%);
  white-space: nowrap;
  font-weight: 600;
  text-decoration-line: underline;
  color: white;
  cursor: pointer;
}

.passed {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}

.inner {
  display: flex;
  align-items: center;
  gap: 7px;
}

.completed {
  width: 18px;
  height: 18px;
  margin-inline: 5px;
  position: inherit;
}

.coin,
.selected .coin,
.coinActive,
.selected .coinActive {
  position: absolute;
  top: -111px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  transition: all 0.5s;
}

.coin {
  height: 111px;
  transition: all 0.5s;
}

.coinActive {
  height: 165px;
  transition: all 0.5s;
}

.selected .coin,
.selected .coinActive {
  top: -165px;
  transition: all 0.5s;
}

.prev svg {
  transform: rotate(-90deg);
}
.next svg {
  transform: rotate(90deg);
}
.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}
.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

.ellipse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/ellipse-currentrank.png");
  background-repeat: no-repeat;
  z-index: 0;
  opacity: 0.8;
}

.ellipseTwo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/ellipse-currenrank-two.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: 0;
  opacity: 0.8;
}
