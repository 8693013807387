.titleCard {
  border-bottom-color: var(--bg-like-blur);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 1px;
  margin-top: 30px;
  overflow: hidden;
}

.poolCard {
  border-top-color: var(--bg-like-blur);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.pagination {
  display: none;
}

.StatusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.filterTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--bg-like-blur);
  justify-content: space-between;
  border-radius: 50px;
  gap: 4px;
}

.button,
.selectedButton,
.filterTitle {
  color: #9a9a9a;
  width: 60px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--bg-select);
  font-weight: 500;
}

.button,
.filterTitle {
  background-color: var(--bg-select);
  transition: all 0.3s ease-out;
  outline: 1px solid transparent;
}

.selectedButton {
  outline: 1px solid #00a88b;
  background-color: var(--bg-like-blur);
  transition: all 0.3s ease-in;
  color: #00a88b;
}

.button:hover {
  cursor: pointer;
  outline: 1px solid #00a88b;
}

.button.hoverable:hover {
  cursor: not-allowed;
  outline: 1px solid var(--error); /* Red outline on hover for hoverable buttons */
}

@media screen and (max-width: 768px) {
  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
}

@media screen and (max-width: 575px) {
  .filterWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 400px) {
  .buttonGroup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
  }
}
