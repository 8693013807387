.balanceContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: column;
}

.balanceWrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.imageContainer{
  display: flex;
}

.balance{
  font-size: 14px;
  font-weight: 600;
}

.unit{
  font-size: 12px;
  font-weight: 500;
}

.infoBoxAction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  color: var(--light);
  cursor: pointer;
  font-size: 12px;
  margin-left: 30px;
}

.infoBoxAction:hover {
  color: var(--text-selected-number);
}

.infoBoxAction svg path {
  transition: all 0.3s ease-in;
}
.infoBoxAction:hover svg path {
  stroke: var(--text-selected-number);
}

.infoBoxActionTitle {
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
  transition: all 0.3s ease-in;
}
