.relativeBox {
  position: relative;
  z-index: 2;
  width: 200px;
  height: 200px;
}

.relativeBox .tooltipText {
  min-width: 200px;
  visibility: hidden;
  padding: 8px 14px;
  border-radius: 8px;
  background: rgb(71, 71, 71, 0.7);
  text-align: center;
  position: absolute;
  z-index: 10;
  left: 0;
  font-size: 13px;
  top: -40px;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  white-space: nowrap;
}

.relativeBox:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
.tooltipTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tooltipText span {
  font-size: 14px;
  font-weight: 400;
  color: #f1f1f1e5;
}

/* wrapper chart */

.wrapperBackground {
  position: absolute;
  z-index: 2;
  width: 200px;
  height: 200px;
}

.wrapperDoughnut {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 200px;
  height: 200px;
}

/* middle chart */

.middleBackground {
  position: absolute;
  z-index: 2;
  width: 165px;
  height: 166px;
  top: 17px;
  left: 17px;
}

.middleDoughnut {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 165px;
  height: 166px;
}

/* inner chart */
.innerBackground {
  position: absolute;
  z-index: 2;
  width: 131px;
  height: 131px;
  top: 34px;
  left: 34px;
}

.innerDoughnut {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 131px;
  height: 131px;
}
