.header {
  height: var(--header-main);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 50px;
}

.finder {
  display: flex;
  align-items: center;
  column-gap: 35px;
  position: relative;
  z-index: 1000;
}

.finder img {
  position: relative;
  z-index: 80;
  display: none;
}

.searchbar {
  background-image: url("../../../assets/icons/lents.svg");
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 15px;
  padding-left: 40px;
}

.information {
  display: flex;
  align-items: center;
  column-gap: 35px;
  height: 100%;
  flex-grow: 2;
  justify-content: flex-end;
}

.time {
  padding: 10px 13px;
  color: var(--light);
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-like-blur);
  white-space: nowrap;
}
.idContainer {
  background: var(--bg-like-blur);
  padding: 10px 13px;
  color: var(--light);
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
}

.avatar {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
}

.menu,
.menuOpen {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  padding-top: 50px;
  transition: all 0.3s;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  width: 250px;
}

.menuOpen {
  opacity: 1;
  visibility: visible;
  height: auto;
  z-index: 200;
}

.content {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.85);
  padding: 15px 20px 5px;
}

.title {
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff1a;
  text-transform: uppercase;
}

.title span {
  font-size: 14px;
  color: var(--text-primary);
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.bid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--card-border);
}

.item {
  font-size: 14px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.item:hover {
  color: var(--text-selected);
}

.item svg {
  flex-shrink: 0;
  font-size: 20px;
}

.picture {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: #36363733;
  background-size: cover;
  background-position: center;
}

.action,
.actionOpen {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}

.action svg,
.actionOpen svg {
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.actionOpen svg {
  transform: rotate(180deg);
  font-size: 20px;
}

.username {
  color: var(--light);
  font-size: 14px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action svg {
  font-size: 20px;
}

.time .label {
  font-variant: small-caps;
  font-size: 14px;
}

.time .dataFormat {
  width: 75px;
  text-align: right;
  margin-right: 5px;
}

@media screen and (max-width: 1199px) {
  .finder img {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .time,
  .avatar {
    display: none;
  }
}
