.animation {
  animation: var(--fadeInAndScrollUpForSteps);
  display: flex;
  flex-direction: column;
  max-width: 720px;
  height: 100%;
}

.title {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}
.childContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.subTitle {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 36px;
  color: var(--text-primary);
}

.buttonStyle {
  display: flex;
  justify-content: space-between;
}

.ButtonStyleOnPrevious {
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  vertical-align: bottom;
}

.leftButton {
  padding: 16px 36px;
  background-color: var(--bg-select);
  border-radius: 4px;
}

.rightButton {
  padding: 16px 36px;
  border-radius: 4px;
}
