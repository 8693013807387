.gridWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.gridWrapperSmall {
  display: grid;
  gap: 10px;
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 250px;
}

.normalBar {
  width: 1px;
  height: 56px;
  background-color: #3D3D3D;
  margin-right: 5%;
}

.card:last-child .normalBar {
  display: none;
}

.normalBar:last-child {
  display: none;
}

.infoWrapper {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.infoDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  color: #F8FAF8;
  font-size: 20px;
  font-weight: 700;
}

.infoDetails p {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--light-grey);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.card:hover .neonBar {
  box-shadow: 0 0 12px var(--text-selected);
  background-color: var(--text-selected);
  color: var(--text-selected);
  transition: all 0.3s ease;
}

.timerContainer{
  width: 100%;
}

.timer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.timerLabel{
  text-align: center;
  font-weight: 700;
  font-size: var(--font-size-base);
}

.dateItem {
  text-align: center;
}

.dateItem .value {
  font-size: var(--font-size-md);
  font-weight: 700;
  color: var(--light);
  margin-bottom: 2px;
}

.dateItem .label {
  font-size: 14px;
  font-weight: 400;
  color: var(--light);
}

.qualified {
  color: var(--text-qualified);
}

.disqualified {
   color: var(--text-disqualified);
}
@media screen and (max-width: 991px) {
  .gridWrapper {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  }
 
}

@media screen and (max-width: 575px) {
  .gridWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .normalBar {
    display: none;
  }
}
