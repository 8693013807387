.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.activity,
.oneActivity {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 30px;
  position: relative;
  padding-bottom: 30px;
}

.activityTitle {
  margin-bottom: 32px;
}

.activityCard {
  flex-grow: 1;
  color: var(--text-units);
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  border: none;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--bg-select);
}

.activityCard:before {
  content: "";
  width: 20px;
  height: 20px;
  border: none;
  position: absolute;
  background: var(--bg-select);
  top: 35%;
  left: -20px;
  -webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
}

.imgCategory {
  position: absolute;
  bottom: -15px;
  right: -20px;
  width: 100px;
  transform: rotate(-10deg);
}
.activityDate {
  color: var(--text-units);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 100px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.activityList > .activity,
.activityList > .oneActivity {
  position: relative;
}
.activityList .activity::before,
.activityList .oneActivity::before {
  content: url("../../../../../assets/svg/activityIcon.svg");
  font-size: 20px;
  z-index: 2;
}
.activityList .activity:after {
  position: absolute;
  left: 8.5px;
  top: 40%;
  content: "";
  width: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235A5A5AFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  height: 100%;
  z-index: 1;
}

.activityList .activity:last-of-type:after {
  top: -60%;
}
.emptyMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.next svg {
  transform: rotate(180deg);
}

.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242222;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}

.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

@media screen and (max-width: 991px) {
  .activityDate {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .activityCard:before {
    display: none;
  }
  .activityList .activity:before,
  .activityList .oneActivity:before {
    display: none;
  }
  .activityList .activity:after {
    display: none;
  }
}
