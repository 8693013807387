.pagination {
  display: none;
}
.pastReferral {
  overflow: visible;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.actions {
  display: flex;
  align-items: center;
  gap: 14px;
}

/* Gradient Button Style */
.button,
.buttonSelected {
  --border-width: 1px;
  --border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  color: #ffffffa1;
  padding: 10px 20px;
  border-radius: var(--border-radius);
}

.button {
  background-color: #292929;
}

.buttonSelected {
  background: linear-gradient(214deg, #35d7bb 7.64%, #354da1 93.97%);
  position: relative;
  z-index: 1;
}

.buttonSelected::before {
  content: "";
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  background: #292929;
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}

.button:hover {
  background-color: #303030;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.tableCard {
  border: none;
}
@media screen and (max-width: 576px) {
  .actions {
    display: grid;
  }
  .titleContainer {
    gap: 25px;
    flex-direction: column;
    align-items: flex-start;
  }
}
