.center {
  padding-block: 30px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 560px;
  padding-inline: 15px;
  margin-inline: auto;
  text-align: center;
}

.content {
  background: var(--bg-like-blur);
  padding: 35px 30px;
  border-radius: 10px;
  width: 100%;
}

.content > img {
  width: 250px;
}

.content h6,
.content h4 {
  color: #cacaca;
  font-variant: small-caps;
}

.content img {
  margin-block: 8px 20px;
}

.content p {
  font-size: 15px;
  color: #cecece;
  margin-block: 10px 35px;
  max-width: 320px;
  margin-inline: auto;
}

.button {
  color: var(--light);
  background-color: #0000005b;
  border-radius: 10px;
  height: auto;
  transition: all 0.3s ease;
  padding: 0;
}

.button img {
  margin: 0;
}

.button:hover {
  background-color: #000000;
  cursor: pointer;
}

.box {
  padding: 12px 25px;
  display: flex;
  border-right: 1px solid rgba(240, 240, 240, 0.05);
  background-color: #00000060;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.box img {
  width: 40px;
  max-height: 28px;
}

.label {
  padding: 12px 25px;
}

.sign {
  font-size: 13px;
  margin-top: 15px;
  color: #cecece;
}

.sign span {
  color: var(--text-selected);
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .button {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content > img {
    width: 180px;
  }
}
