.center {
  padding-block: 30px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 560px;
  padding-inline: 15px;
  margin-inline: auto;
  text-align: center;
}

.content {
  width: 100%;
}

.loaderBox {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 0;
}
.rotate {
  animation: rotation 2s infinite linear;
}
.fixed {
  transform: translateX(-20px);
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

.errorPopup {
  text-align: center;
}

.errorPopup p {
  margin-block: 20px 30px;
}

.button {
  width: 100%;
  max-width: 150px;
}
