.emptyCard {
  padding: 0;
  background: none;
  display: grid;
  row-gap: 25px;
  border: none;
}

.userCardGrid {
  display: grid;
  grid-template-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  justify-items: start;
  gap: 25px;
}

.cardChildren {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.singleUsersCard {
  text-align: center;
}

.singleUsersCard p {
  color: var(--text-primary);
  font-size: 14px;
  margin-bottom: 10px;
}

.amountWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.amountTop {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.amountTop span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.usersTarget {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 14px;
  color: var(--text-primary);
  margin-top: 10px;
}

.usersTarget span {
  font-size: 12px;
  font-weight: 400;
}

.verticalSeparator {
  height: 100%;
  min-height: 85px;
  width: 1px;
  margin: auto;

  background: linear-gradient(
    218deg,
    rgba(255, 255, 255, 0) 0%,
    #858585 41.67%,
    rgba(252, 252, 252, 0) 100%
  );
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overflow {
  overflow-x: auto;
  padding-bottom: 20px;
  user-select: none;
  scroll-behavior: smooth;
  --webkit-overflow-scrolling: touch;
  margin-bottom: 15px;
  min-height: 560px;
}

.container {
  display: flex;
  white-space: nowrap;
  gap: 66px;
  padding-top: 80px;
}

.containerInfo {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  gap: 66px;
  position: relative;
  top: -70px;
}

.name {
  font-size: 15px;
  color: var(--light);
  min-width: 80px;
  text-align: center;
}

.name div {
  margin-top: 4px;
  font-size: 13px;
  color: #8a8a8a;
}

.position {
  max-width: 62px;
  position: relative;
  top: -23px;
  z-index: 2;
  left: 50%;
  transform: translateX(calc(-50% - 18px));
  text-align: center;
  font-size: 19px;
  font-weight: 700;
}

.label {
  font-size: 13px;
  font-weight: 400;
  color: #8a8a8a;
  margin-bottom: 3px;
}

.position span {
  display: block;
  font-size: 11px;
  font-weight: 500;
}

.information {
  margin-top: 30px;
}

.information p {
  margin-block: 10px 20px;
  font-weight: 500;
}

.description {
  font-size: 15px;
}

.information h6 {
  margin-block: 13px 5px;
}

@media screen and (max-width: 991px) {
  .userCardGrid {
    grid-template-columns: 1fr 1fr;
  }
  .verticalSeparator {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    row-gap: 10px;
  }
  .userCardGrid {
    grid-template-columns: 1fr;
  }
}

.pagination {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  z-index: 2;
}

.prev svg {
  transform: rotate(-90deg);
}
.next svg {
  transform: rotate(90deg);
}
.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}
.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}
