.title {
  font-size: var(--font-size-md);
  font-weight: 600;
  color: var(--light);
  margin-bottom: 25px;
}
.subTitle {
  color: var(--text-primary);
  font-size: 14px;
  margin-bottom: 32px;
}
.infoBoxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.infoBoxCard {
  padding: 15px 20px 20px;
  max-width: 320px;
  width: 100%;
  position: relative;
  overflow: visible;
}
.cardTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--light);
  margin-bottom: 27px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 10px;
}
.tooltip {
  background-image: url(../../../../../assets/svg/info.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
}
.tooltip .tooltipText {
  max-width: 250px;
  visibility: hidden;
  padding: 14px;
  border-radius: 8px;
  background: #474747;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0px;
  font-size: 13px;
  top: 30px;
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltipText,
.tooltip.active .tooltipText {
  visibility: visible;
  opacity: 1;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
}
.value {
  font-size: var(--font-size-md);
  font-weight: 600;
  color: var(--light);
}
.unit {
  font-size: 12px;
  color: var(--light);
}

@media screen and (max-width: 991px) {
  .tooltip {
    max-height: 80px;
  }
}
