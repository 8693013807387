.header {
  margin-block: 32px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title h4 {
  padding-right: 20px;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-image: linear-gradient(
      to top,
      rgba(68, 68, 68, 0) 0%,
      rgba(169, 169, 169, 0.6) 51.35%,
      rgba(68, 68, 68, 0) 100%
    )
    1 10%;
  height: 100%;
}

.title svg {
  margin-top: 3px;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.title span {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-primary);
  font-size: 14px;
}

.title svg:hover {
  color: var(--text-selected);
}
.rotating {
  animation: rotate 3s linear infinite;
}

.copyBtn {
  cursor: pointer;
  justify-self: flex-end;
  position: relative;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.copyBtn svg {
  width: 20px;
  height: 20px;
}

.copyBtn:hover,
.copyBtn:hover svg path {
  stroke: var(--text-selected-number);
  transition: all 0.3s ease;
}

.leftButton{
  padding: 16px 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  width: 230px;
  background-color: transparent;
  color: var(--button);
  border: 1px solid var(--button);

}

@media screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
  .copyBtn {
    justify-content: center;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
  
}
