.generalRankCard {
  display: flex;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}
.currentRankCard {
  display: flex;
  justify-content: space-between;
}
.rankInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.rankImg {
  margin-block: auto;
  flex-wrap: wrap;
}
.rankImg img {
  max-width: 100%;
  height: auto; /* maintain aspect ratio */
  object-fit: cover;
}
.title {
  text-align: left;
  /* width: 175px; */
  max-width: fit-content;
  white-space: wrap;
  font-size: var(--font-size-md);
  font-weight: 600;
  color: var(--light);
  margin-bottom: 14px;
}
.subTitle {
  text-align: left;
  /* width: 175px; */
  max-width: fit-content;
  color: var(--text-primary);
  font-size: 14px;
  margin-bottom: 32px;
}
.infoBoxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.infoBoxCard {
  padding: 15px 20px 20px;
  position: relative;
  overflow: visible;
}
.cardTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--light);
  margin-bottom: 27px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip {
  background-image: url(../../../../assets/svg/info.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
}
.tooltip .tooltipText {
  max-width: 250px;
  visibility: hidden;
  padding: 14px;
  border-radius: 8px;
  background: #474747;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0px;
  font-size: 13px;
  top: 30px;
  opacity: 0;
  transition: opacity 0.5s;
}
.tooltip:hover .tooltipText,
.tooltip.active .tooltipText {
  visibility: visible;
  opacity: 1;
}
.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.value {
  font-size: var(--font-size-md);
  font-weight: 600;
  color: var(--light);
}
.unit {
  font-size: 12px;
  color: var(--light);
}

@media screen and (max-width: 991px) {
  .generalRankCard {
    grid-template-columns: 1fr;
    gap: 60px;
  }
  .tooltip {
    max-height: 80px;
  }
  .infoBoxes {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .infoBoxes {
    grid-template-columns: 1fr;
  }
  .infoBoxCard {
    min-width: unset;
    width: 100%;
    overflow: hidden;
  }
}
@media screen and (max-width: 576px) {
  .currentRankCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .title,
  .subTitle {
    text-align: center;
  }
  .rankDate {
    margin-inline: auto;
    margin-block: 8px 12px;
  }

  .content {
    flex-wrap: wrap;
  }

  .cardTitle {
    white-space: wrap;
  }

}
