.layout {
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url("../../assets/svg/headerGradient.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-color: var(--bg-primary);
}

.sidebar {
  min-width: 250px;
  height: 100%;
  overflow: hidden;
}

.content {
  width: calc(100% - 250px);
  color: white;
  height: 100%;
  float: left;
}

.navbarContent {
  position: relative;
  border-bottom: 1px solid rgba(228, 228, 228, 0.15);
}

.outlet {
  height: calc(100% - 80px);
  overflow: scroll;
  overflow-x: hidden;
  /* overflow-y: overlay; */
  padding-bottom: 100px;
}

/* .bg {
  background-image: url("../../assets/images/bg-layout2.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: top;
} */

.borderWarning,
.borderWarnSide {
  height: 30px;
  width: 100%;
  position: absolute;
  background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 15px,
      #0f0f0d 15px,
      #0f0f0d 30px
    ),
    linear-gradient(to bottom, #f9cc0f, #f9cc0f);
  z-index: 99998;
}

.borderWarnSide {
  height: 100%;
  width: 16px;
}

.mode,
.modeBottom {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  padding: 0 10px;
  color: var(--error);
  width: 280px;
  font-weight: 800;
  margin-inline: auto;
  height: 25px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: colorBrightness 2s infinite;
}

.closeBtn{
  margin-top: 20px;
}

/*  animation color brightness */
@keyframes colorBrightness {
  0% {
    filter: brightness(1.3);
  }
  50% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(1.3);
  }
}

@media screen and (max-width: 1199px) {
  .content {
    width: 100%;
  }
}
