.card {
  background: url("../../../../../assets/images/reportBuilderUserInfoBG.png")
      no-repeat,
    var(--bg-like-blur);
  background-position: bottom left;
  padding: 27px 35px;
}

.userInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.leftSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.avatar {
  height: 94px;
  width: 94px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftSection > img {
  border-radius: 100px;
}

.username {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 17px;
  word-break: break-all;
}

.email {
  color: var(--light);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.additionalInfo {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.additionalInfoItem,
.userTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 7px;
}

.additionalInfoItem > :not(:first-child) {
  margin-right: 8px;
}

.userTextWrapper {
  display: flex;
}

.verticalDivider {
  width: 1px;
  height: 21px;
  background-color: #3d3d3d;
}

.additionalInfoTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--text-primary);
}

.idSection {
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--bg-primary);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--light);
}
.rankRule {
  font-size: var(--font-size-sm);
  text-decoration: underline;
}
.rankRuleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

@media screen and (max-width: 575px) {
  .verticalDivider {
    width: unset;
    height: unset;
    background-color: unset;
  }
}

@media screen and (max-width: 350px) {
  .userTextWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .additionalInfoContent {
    word-break: break-all;
  }
}
