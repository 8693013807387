.stepsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 50px;
  z-index: 1;
}

.stepsLine {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 19px;
  border-left: 0.5px solid var(--list-line);
  z-index: -1;
  height: 90%;
  margin-top: 10px;
}

.step,
.notAllowed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}

.stepOrder,
.stepOrderInProgress {
  width: 38px;
  height: 38px;
  background-color: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stepOrder {
  color: var(--card-border);
}

.stepOrderInProgress {
  background: linear-gradient(214deg, #35d7bb 7.64%, #354da1 93.97%);
}

.titleLocked,
.title {
  font-weight: 600;
}

.titleLocked {
  color: var(--text-small-label);
}

.stepCompletedLabel,
.stepInProgressLabel {
  color: var(--text-completed);
  font-size: 12px;
  font-weight: 500;
}

.stepInProgressLabel {
  color: var(--text-In-progress);
}

@media screen and (max-width: 991px) {
  .stepsContainer {
    gap: 28px;
  }
}
