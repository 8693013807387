.card {
  position: relative;
  background-blend-mode: overlay, normal;
  padding: 30px 25px;
  overflow: visible;
  transition: all 0.3s ease;
  border: 1px solid var(--card-border);
  border-radius: 8px;
  background: var(--bg-like-blur);
}
