.timer {
  position: relative;
}

.numbers {
  color: var(--text-selected);
  font-weight: bold;
  font-size: clamp(1.5rem, 2.5vmin, 2.3vmin);
  min-width: 165px;
  text-align: center;
}

.numbers span {
  font-size: clamp(0.8rem, 1vmin, 1vmin);
}

.left {
  font-size: clamp(1rem, 1.2vmin, 1.2vmin);
  margin-top: 2px;
}
