.treeCard {
  margin-bottom: 25px;
  background: none;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 14px;
}

/* Gradient Button Style */
.button,
.buttonSelected {
  --border-width: 1px;
  --border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  color: #ffffffa1;
  padding: 10px 20px;
  border-radius: var(--border-radius);
}

.button {
  background-color: #292929;
}

.buttonSelected {
  background: linear-gradient(214deg, #35d7bb 7.64%, #354da1 93.97%);
  position: relative;
  z-index: 1;
}

.buttonSelected::before {
  content: "";
  display: block;
  height: calc(100% - calc(var(--border-width) * 2));
  width: calc(100% - calc(var(--border-width) * 2));
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  background: #292929;
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}

.button:hover {
  background-color: #303030;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
/* End Gradient Button Style */
.fullScreen {
  color: #868686;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fullScreen:hover {
  color: var(--text-selected);
}

.fullScreen span {
  text-decoration: underline;
}

.fullScreen svg {
  font-size: 25px;
}

.border {
  margin-inline: 10px;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-image: linear-gradient(
      to top,
      rgba(68, 68, 68, 0) 0%,
      rgba(169, 169, 169, 0.6) 51.35%,
      rgba(68, 68, 68, 0) 100%
    )
    1 10%;
  height: 50px;
}

.searchContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.searchInput{
  margin-top: 0;
}
/* animated spin button start */
.buttonContainer {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  padding: 1px;
  
}



.buttonBackground {
  position: absolute;
  inset: -500%;
  animation: spin 2s linear infinite;
  background: conic-gradient(from 2400deg at 50%,#354da1 0%, #35d7bb 50%, #354da1 93.97%);
}

.buttonBackgroundRm {
  display: none;

}

.buttonContent {
  display: inline-flex;
  --border-width: 1px;
  font-size: 15px;
  font-weight: 600;
  color: #ffffffa1;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #292929;
  backdrop-filter: blur(12px); /* backdrop-blur-3xl */

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* animated spin button end */

@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .actions {
    justify-content: flex-end;
    width: 100%;
  }
}
.contentWrapper {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 575px) {
  .actions {
    flex-direction: column;
  }
  .border {
    display: none;
  }
}

/* Custom Loader  */
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: linear-gradient(0deg, rgba(0, 0, 0) 0%, #333131 100%);
}

.defaultLoaderImage {
  max-width: 150px;
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loaderBox {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 0;
}
.rotate {
  animation: rotation 2s infinite linear;
}
.fixed {
  transform: translateX(-20px);
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
