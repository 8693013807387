.timer {
  width: 200px;
  height: 200px;
  border: 9px solid transparent;
  border-radius: 50%;
  position: relative;
}

.numbers {
  color: var(--text-selected);
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1.5rem, 2.5vmin, 2.3vmin);
  width: 100%;
  text-align: center;
}

.numbers span {
  font-size: clamp(0.8rem, 1vmin, 1vmin);
}

.left {
  font-size: clamp(1rem, 1.2vmin, 1.2vmin);
  margin-top: 2px;
}
