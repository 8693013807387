/* Container */

.container {
  max-width: 1550px;
  padding-inline: 20px;
}

@media screen and (max-width: 575px) {
  .container {
    padding-inline: 10px;
  }
}

/* Button */
.buttonStyle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background-color: var(--button);
  transition: all 0.3s ease;
  user-select: none;
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  height: 50px;
}

/* .buttonStyle:hover {
  background-color: var(--auth-primary-hover);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.26);
} */

.buttonStyle:active {
  scale: 1.05;
}

.buttonStyleDisabled {
  opacity: 0.5;
}

/* .buttonStyleDisabled:hover {
  box-shadow: none;
} */

.buttonStyleDisabled:active {
  scale: 1;
}

.downloadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  position: relative;
  min-width: 230px;
  border-radius: 6px;
  color: var(--light);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  background: var(--bg-like-blur-2);
}

/** INPUTFIELD */
.inputFieldLabelStyle {
  color: var(--text-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
}

.errorMessageClassName {
  min-height: 15px;
  font-size: 12px;
  letter-spacing: -0.5px;
  text-align: center;
  color: var(--text-error);
  margin-top: 2px;
}

.inputFieldStyle,
.inputFieldErrorStyle,
.inputFieldSuccessStyle {
  background: transparent;
  border: 1px solid var(--card-border);
  border-radius: 3px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 10px;
}

.inputFieldStyle input,
.inputFieldErrorStyle input,
.inputFieldSuccessStyle input {
  caret-color: var(--primary);
  color: var(--light);
  padding: 0;
}

.inputFieldStyle input::placeholder {
  color: var(--text-placeholder);
  font-size: 16px;
  opacity: 0.8;
}

/** SELECT */

/* #1 */
.selectClass {
  border: none;
  padding: 16px;
  min-width: 150px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: var(--font-size-sm);
  border-radius: 6px;
  color: var(--text-primary);
  transition: all 0.4s ease;
  background: var(--bg-select);
}

.selectPlaceholderClass {
  color: var(--text-primary);
}

.selectOpenedClass {
  z-index: 9;
  position: relative;
  overflow-y: overlay;
  width: 100%;
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  scrollbar-color: var(--text-primary) transparent; /* Only Firefox */
  scrollbar-width: 2px;
  background: var(--bg-select);
}

.selectOpenedClass::-webkit-scrollbar {
  width: 2px; /* Chrome */
}

.selectOpenedClass::-webkit-scrollbar-thumb {
  background-color: var(--text-primary);
}

.selectOptionClass {
  padding: 15px;
  color: var(--text-primary);
}

.selectOptionClass:hover {
  background-color: rgba(128, 128, 128, 0.226);
}

/* Modal */
.magicModal {
  border-radius: 8px;
  padding: 28px 22px;
  background: url("../../assets/images/modalTopLeftBg.png.png") no-repeat top
      left,
    /* url("../../assets/images/modalBottomRightBg.png.png") no-repeat bottom right, */
      var(--bg-modal);
  color: var(--light);
  animation: modalappear 0.3s ease;
  max-width: 555px;
}

@keyframes modalappear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------ Otp Component ------------- */
.otpContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.otpInputStyle,
.otpErrorStyle {
  height: 57px;
  width: 100% !important;
  font-size: 24px;
  background: transparent;
  border-radius: 8px;
  caret-color: var(--light);
  color: var(--light);
}

.otpInputStyle {
  border: 1px solid var(--card-border);
}
.otpErrorStyle {
  border: 1px solid rgba(190, 17, 17, 0.4);
}
.otpFocusStyle {
  outline: none;
  border: 2px solid var(--text-selected-number);
}

.otpDisabledStyle {
  opacity: 0.3;
  user-select: none;
  cursor: not-allowed;
}

@media screen and (max-width: 1199px) and (min-width: 991px) {
  .otpInputStyle {
    height: 2.5em;
  }
}

@media screen and (max-width: 575px) {
  .otpInputStyle {
    height: 2.5em;
  }
  .magicModal {
    padding: 15px;
  }
}
@media screen and (max-width: 480px) {
  .otpInputStyle {
    height: 1.85em;
  }
  .magicModalContent {
    padding: 15px;
  }
  .downloadBtn{
    min-width: unset;
  }
}
/* ------------ Otp Component ------------- */

/* ------------ Wallets ------------- */
.walletCardTitle {
  margin-bottom: 25px;
  text-transform: capitalize;
}

.walletDetails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 20%;
}

.walletDetails > div {
  flex-grow: 1;
}

/* ------------ Wallets ------------- */
