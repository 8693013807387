.treeCard {
  background-color: transparent;
}

.heading {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.heading svg {
  font-size: 20px;
  color: gray;
}

.lastReferral {
  color: gray;
}

.list_member {
  overflow-x: scroll;
  padding-bottom: 20px;
}
.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #7730f6;
  background-position: center;
  background-size: contain;
  margin-left: 25px;
  background-repeat: no-repeat;
}
.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #bcbcbc3b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 15px;
}
.avatarPrivate {
  overflow: hidden;
}
.avatar_child_1 {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: red;
  background-position: center;
  background-size: cover;
  margin-left: 55px;
}
.title_member {
  font-weight: 600;
  line-height: 27px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.username {
  width: 260px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.username_member {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #7730f6;
}
.id_member {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #a6b2be;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 2px;
  cursor: pointer;
  display: none;
}

.text_icon_plus,
.textIconPlusLoading {
  color: #07f9c8;
  border-radius: 2.1222px;
  padding: 2px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
  display: block;
  z-index: 9;
  background-color: #212121;
  
}
.textIconPlusLoading::before {
  animation: rotation 2s infinite linear;
  content: "";
  cursor: not-allowed;
  position: absolute;
  top: 0;
  left: -25px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 2;
  background-color: #212121;
  background-image: url(../../../../../assets/svg/node-loading.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.textIconPlusLoading svg {
  opacity: 0; /* Hide the svg icon when loading */
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.text_icon_plus svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-135%, -50%);
  width: 30px;
  height: 30px;
}
.icon_circle {
  color: #a8cdff;
  border-radius: 2.1222px;
  padding: 2px;
  /* cursor: pointer; */
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle_final {
  border-radius: 50%;
  background-color: var(--secondary);
  width: 10px;
  height: 10px;
  position: relative;
  z-index: 10;
  transform: translate(-5px, 0px);
}
.text_icon_plus_child {
  color: #a8cdff;
  border: 0.707401px solid #a8cdff;
  background-color: white;
  border-radius: 2.1222px;
  padding: 2px;
  cursor: pointer;
  opacity: 0;
  position: relative;
  z-index: -1;
}
.button_toggle {
  color: #a8cdff;
  background-color: white;
  border: none;
  outline: none;
  position: absolute;
  top: 100px;
  left: 25px;
  font-size: 15px;
  border: 0.707401px solid #a8cdff;
  padding: 0 5px;
  border-radius: 2.1222px;
  cursor: pointer;
}
.plus_border,
.plus_border_first {
  position: relative;
  margin-left: 10px;
}

.coins,
.personal_poll,
.ratio,
.member,
.status,
.active_pools,
.view_points,
.member_since {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
.coins img {
  width: 50px;
}
.coins,
.personal_poll,
.ratio,
.status,
.member,
.active_pools,
.view_points,
.member_since {
  min-width: 200px;
}
.coins {
  min-width: 100px;
}
.status {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
}

.view_points:hover {
  cursor: pointer;
}

.container_max {
  width: max-content;
  /* padding: 20px 0 0; */
}
.containerFrame {
  position: relative;
  display: flex;
  width: 100%;
}

.loadmore {
  height: 35px;
  background-color: #57575780;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  width: 130px;
  margin-top: 10px;
  position: relative;
}

.loadDot {
  position: relative;
}

.dotBefore {
  position: absolute;
  height: 45px;
  width: 1px;
  top: calc(-50px);
  left: -63px;
  border-left: 1px dashed #5d6e82;
}

.dotAfter {
  position: absolute;
  height: 45px;
  width: 1px;
  top: calc(-50px);
  left: -16px;
  border-left: 1px dashed #5d6e82;
}

.loadmore:hover {
  background-color: #575757;
}

.loadmoreCore {
  width: 280px;
  margin-top: 50px;
}

.backgroundRowOne {
  position: relative;
  z-index: 12;
  height: 90px;
  display: flex;
  width: max-content;
  background: #212121;
  border: 1px solid #3D3D3D;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  margin-left: 14px;
}

.cardFrame {
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #d9d9d9; */
  z-index: 0;
  bottom: -5px;
  left: 5px;
  opacity: 0.3;
  /* background: linear-gradient(136deg, rgba(9, 212, 127, 0.83) 0%, #149dcb 100%); */
}
.cardFrameAllflex {
  position: absolute;
  height: 44px;
  border-radius: 8px;
  z-index: 0;
  top: -4px;
  right: -40px;
}

.button_more_folder {
  font-style: normal;
  font-weight: 400;
  background-color: white;
  height: 20px;
  width: 20px;
  font-size: 12.32px;
  line-height: 18px;
  color: #5a626b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin-left: 7px;
}
.button_more_folder:hover {
  background-color: #f2f4f8;
}

.setting_popup_folder {
  position: absolute;
  right: -215px;
  top: -60px;
  padding: 12px 19px 5px;
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: initial;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.setting_popup_folder h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #748194;
}
.setting_popup_folder p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #5d6e82;
}
.setting_popup_folder p:hover {
  cursor: pointer;
  color: #7730f6;
}
.allFlex {
  display: flex;
  align-items: center;
  width: 100%;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.rankCoin {
  position: absolute;
  bottom: -5px;
  right: -5px;
  height: 27px;
  width: 27px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

.secondChild .allFlex {
  background: #212121;
  border: 1px solid #3D3D3D;
  border-radius: 5px 0 0 5px;
  border-right: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
  z-index: 10;
}
.rightSecondChild {
  display: flex;
  background: #212121;
  border: 1px solid #3D3D3D;
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  z-index: 10;
}

.section {
  background-color: transparent;
}

.activeStatus {
  color: var(--success);
}

.inactive {
  color: var(--warning);
}

.noLine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 820px;
}

.builderReportButton {
  margin-left: 10px;
  cursor: pointer;
}

.builderReportButton:hover {
  opacity: 0.5;
  transition: all 0.3s ease;

}
