.modalContent {
  padding: 36px;
}

.modalTitle {
  font-size: var(--font-size-base);
  font-weight: 700;
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--card-border);
  margin-bottom: 32px;
}

.amount {
  position: relative;
}

.balance {
  position: absolute;
  right: 20px;
  top: 3px;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 14px;
}

.inputNumberAmount input {
  color: var(--light);
  border: none;
  background: transparent;
}

.amountContainer {
  margin-bottom: 36px;
}

.instruction {
  margin-bottom: 36px;
}

.instructionTitle {
  margin-bottom: 10px;
  color: var(--light);
}

.instructionText {
  color: var(--text-primary);
  font-size: 14px;
}

.sendActions {
  display: flex;
  gap: 14px;
}

.sendActions > div {
  width: 100%;
  height: 100%;
}

.cancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 15px 28px;
  border-radius: 8px;
  border: 1.2px solid var(--text-selected-number);
  color: var(--text-selected-number);
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-base);
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.cancelBtn:hover {
  color: var(--light);
  border: 1.2px solid var(--light);
}

.proceed {
  border-radius: 8px;
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.modalOTPTitle {
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
}

.modalOTP {
  margin-bottom: 40px;
}

.otpErrorMessage {
  margin-top: 10px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
  color: var(--error);
}

.link {
  cursor: pointer;
  color: var(--text-selected-number);
}

@media screen and (max-width: 1199px) {
  .balance {
    top: 48%;
  }
}
@media screen and (max-width: 575px) {
  .modalContent {
    padding: 15px;
  }
}
