.center {
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 105px;
  position: relative;
  z-index: 10;
  flex-direction: column;
  color: var(--light);
}

.center img {
  width: 100%;
  max-width: 280px;
}

.info {
  font-size: var(--font-size-md);
  max-width: 600px;
  text-align: center;
}
.info span {
  font-weight: 600;
}

.bg {
  background-image: url("../../assets/images/bg-layout2.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-position: top;
}
