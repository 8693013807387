.wrapper {
  display: inline-block;
  width: 100%;
}
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.titleCard {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
}

.imgCardWrapper {
  margin-block: 40px 60px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.personalImg {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.personal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-primary);
  transition: all 0.3s ease;
}

.username {
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
}

.username:hover,
.personal:hover {
  color: var(--text-selected);
}

.personal svg path {
  stroke: var(--text-primary);
  transition: all 0.3s ease;
}

.personal:hover svg path {
  stroke: var(--text-selected);
}

.valueWrapper{
  border: none;
}
.infoWrapper {
  margin-top: 45px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.right {
  width: fit-content;
  margin-left: auto;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  color: #e4e4e4;
}

.vValue {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.38px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: var(--text-primary);
}

.levelValue {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.38px;
  color: var(--text-primary);
}

.levelValue .extention {
  vertical-align: unset;
}

.extention {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1%;
  vertical-align: text-top;
}

@media screen and (max-width: 576px) {
  .infoWrapper {
    grid-template-columns: 1fr;
  }

  .left,
  .right {
    text-align: center;
  }

  .right {
    margin-inline: auto;
  }

  .vValue {
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .left,
  .right {
    flex-direction: column;
    text-align: center;
  }
}
