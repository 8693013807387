.layout {
  height: 100dvh;
  position: relative;
  z-index: 2;
  overflow: scroll;
  overflow-y: overlay;
  background: var(--bg-primary);
}

.ellipseOne {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  filter: blur(90.5px);
  width: 800px;
  opacity: 0.6;
  mix-blend-mode: color-dodge;
}

.blur {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  backdrop-filter: blur(15px);
}
