.teamGrowthContainer {
  overflow: visible;
  padding: 30px 28px;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.bodyData {
  animation: var(--fadeInAndScrollUpForSteps);
}

.itemBorder {
  background-color: var(--card-border);
  width: 100%;
  margin: auto;
  height: 1px;
  margin-block: 18px;
}

.noDataAvailableBestPerformance {
  min-height: 228px;
  display: flex;
  justify-content: center;
  align-items: center;
}
