.legStats,
.grid {
  display: grid;
  gap: 24px;
  margin-bottom: 24px;
}

.grid {
  grid-template-columns: 1fr 1fr;
}

.legStats {
  grid-template-columns: repeat(12, 1fr);
}

.legStats > :first-child {
  grid-column: span 6;
}

.legStats > :nth-child(2) {
  grid-column: span 6;
}

.slider {
  display: flex;
  gap: 30px;
  overflow: scroll;
  height: 400px;
}

@media screen and (min-width: 1200px) and (max-width: 1370px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1120px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 991px) {
  .grid,
  .legStats {
    grid-template-columns: 1fr;
  }
  .legStats > :first-child {
    grid-column: span 12;
  }
  
  .legStats > :nth-child(2) {
    grid-column: span 12;
  }
}
