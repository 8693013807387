.cardWrapper {
  position: relative;
  transform: translateY(20px);
}

.cardFrame {
  position: absolute;
  width: 220px;
  height: 10px;
  border-radius: 8px;
  z-index: 0;
  top: -4px;
  left: 10px;
  background: linear-gradient(136deg, rgba(43, 94, 73, 0.83) 0%, #43798b 100%);
}

.cardBody,
.cardBodyLoadMore {
  width: 240px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 35px;
  background: linear-gradient(110deg, rgb(91, 87, 87) 0%, #5d5b5b 100%),
    rgba(64, 64, 64, 0.36);
  background-blend-mode: overlay;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.cardBodyLoadMore {
  background-image: repeating-linear-gradient(
      0deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      90deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      180deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      270deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  border-radius: 0;
  cursor: pointer;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-inline: auto;
  position: absolute;
  bottom: 115px;
  left: 95px;
  z-index: 10;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--light);
  font-size: 12px;
}

.usernameStyle {
  font-weight: 600;
  color: var(--light);
}

.rankStyle {
  background: linear-gradient(119deg, #42e8e0 0%, #7ae85c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infinityBonus {
  font-weight: 400;
}

.infinityBonus span {
  font-weight: 600;
}

.userPerformanceLink {
  color: var(--text-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.handleButton {
  width: 35px;
  height: 35px;
  margin: auto;
  cursor: pointer;
}

.customHandleExpand,
.customHandleCollapsed,
.rotating {
  width: 35px !important;
  height: 35px !important;
  background-color: #404040 !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  bottom: 11px !important;
  z-index: 5 !important;
  background-image: url(../../../../../assets/svg/expandIcon.svg) !important;
}

.customHandleCollapsed {
  background-image: url(../../../../../assets/svg/collapseIcon.svg) !important;
}

.rotating {
  position: relative;
}

.rotating::after {
  animation: rotation 2s infinite linear;
  position: absolute;
  width: 35px;
  height: 35px;
  top: -1px;
  left: -1px;
  content: "";
  border-radius: 50%;
  background-image: url(../../../../../assets/svg/node-loading.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: #9c9c9c;
}
.emptyHandle {
  visibility: hidden !important;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
