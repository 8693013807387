.card {
  border: none;
  background: none;
  padding: 0;
}

.cardTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.iconAnimation {
  animation: fadeInOut 3s ease-in-out infinite;
}

.cardsContainerParent {
  overflow: hidden;
  display: flex;
  padding-inline: 10px;
  border-inline: 0.5px solid;
  border-image: linear-gradient(
    336deg,
    rgba(255, 255, 255, 0) 0%,
    #858585 10.67%,
    rgba(252, 252, 252, 0) 123%
  );
}

.cardsContainer,
.cardsContainerClone {
  animation: marquee linear 40s infinite;
}

.flexContainer,
.flexContainerClone {
  display: flex;
  gap: 20px;
}

.flexContainerClone {
  margin-right: -25px;
}

/* -------------------NewUsersCard------------------------ */
.countryCardBody,
.countryCardBodyActive {
  border-radius: 100px;
  width: 200px;
  padding: 10px 20px;
  background-blend-mode: overlay, normal;
  background: var(--bg-select);

  transform: translateY(15px);
  transition: all 0.5s ease-in-out;
}
.countryCardBody {
  opacity: 0;
}
.countryCardBodyActive {
  opacity: 1;
  transform: translateY(0);
}

.countryImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}

.userInformation {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.flag span {
  color: var(--text-placeholder);
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.username {
  text-align: left;
  max-width: 120px;
}

.flag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.country {
  font-size: 14px;
  font-weight: 400;
  color: #888;
}

.name {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes trigger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
