.gradientHeader {
  margin-block: 25px;
}

.section {
  margin-bottom: 25px;
}

.section2 {
  margin-block: 34px 25px;
}
