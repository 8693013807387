.treeCard {
  margin-bottom: 25px;
}

.header {
  margin-bottom: 40px;
}

.header p {
  margin-top: 4px;
  color: var(--text-primary);
}

.actions {
  display: flex;
  align-items: center;
  gap: 14px;
}

.fullScreen {
  color: #868686;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fullScreen:hover {
  color: var(--text-selected);
}

.fullScreen span {
  text-decoration: underline;
}

.fullScreen svg {
  font-size: 25px;
}

.border {
  width: 100%;
  height: 1px;
  min-height: auto;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #858585 41.67%,
    rgba(252, 252, 252, 0) 100%
  );
  margin-bottom: 40px;
}

.titleWrapper {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
  gap: 8px;
}

.next svg {
  transform: rotate(180deg);
}

.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #242222;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}

.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

/* ---------------------- SliderItem -------------------- */
.legendCard {
  padding: 20px;
  position: relative;
  z-index: 1;
  cursor: grab;
}

.legendCard img {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  right: -30px;
  z-index: -1;
  opacity: 0.2;
  transform: translateY(-50%);
}

.legendText {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-primary);
}

@media screen and (max-width: 1199px) {
  .legendCard {
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .actions {
    justify-content: flex-end;
    width: 100%;
  }
}
