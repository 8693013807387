/* _StatisticsUnilevel */
.grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

/* _ActivationWindow */
.activation {
  padding: 28px 22px;
  text-align: center;
}

.shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/ellipse-currentrank.png");
  background-repeat: no-repeat;
  background-size: 80%;
  z-index: -1;
  opacity: 0.3;
}

.head {
  margin-bottom: 32px;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.period {
  column-gap: 6px;
}

.range {
  margin-top: 4px;
}

.range .label {
  font-size: 15px;
  margin-bottom: 6px;
}

.number {
  margin-bottom: 105px;
}

.rankDate {
  background: rgba(255, 255, 255, 0.15);
  padding: 8px;
  text-align: center;
  border-radius: 50px;
  margin-top: 40px;
  font-size: 12px;
  max-width: 140px;
}

/* _StatisticsCard */
.addBackground {
  background: url(../../../../../assets/svg/waveOne.svg) no-repeat,
    var(--bg-like-blur);
  background-size: cover;
  padding: 22px 25px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-units);
  margin-bottom: 19px;
}

.bodyWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.amount {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;
  margin-bottom: 34px;
}
.toolTipFullNumber {
  max-width: 150px;
  visibility: hidden;
  padding: 8px;
  border-radius: 8px;
  background: #474747;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  font-size: 13px;
  opacity: 0;
  transition: opacity 0.5s;
  white-space: nowrap;
}

.amount:hover .toolTipFullNumber,
.amount.showFullNumber .toolTipFullNumber {
  visibility: visible;
  opacity: 1;
}

.amount span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.percentage {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.percentage span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-units);
}

@media screen and (max-width: 991px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
