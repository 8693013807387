.info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: transparent;
  border: 1px solid var(--card-border);
  padding: 16px;
  border-radius: 8px;
}

.copyBtn {
  cursor: pointer;
  justify-self: flex-end;
  position: relative;
}
.copyBtn:hover,
.copyBtn:hover svg path {
  stroke: var(--text-selected-number);
  transition: all 0.3s ease;
}
.toCopy {
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.label {
  color: var(--text-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
  margin-bottom: 10px;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
}
.copyTooltip {
  position: absolute;

  top: -144%;
  left: -29px;

  background-color: var(--dark);
  padding: 5px 10px;
  border-radius: 8px;
  color: var(--light);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
.copyTooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--dark) transparent transparent transparent;
}

.show {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 395px) {
  .toCopy {
    max-width: 15ch;
    text-overflow: ellipsis;
  }
}
