.logoutModal {
  text-align: center;
}

.title {
  margin-inline: auto;
  max-width: 330px;

  margin-bottom: 20px;
}

.info {
  text-align: center;
  padding-inline: 10px;
  max-width: 420px;
  margin-inline: auto;
}

.logoutAll {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-block: 20px;
  color: var(--text-primary);
}

.logoutButton {
  border-radius: 30px;
  width: 100%;
  margin-top: 10px;
  max-width: 300px;
}
