.firstLineUsers {
  padding: 33px 38px;
  overflow: unset;
}

.paginationButton {
  background: transparent;

  color: var(--text-primary);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.paginationButton:hover {
  background: transparent;
}

.toPageInput {
  border: 2px solid #5a5a5a;
  padding: 12px 15px;
  border-radius: 8px;
  width: 47px;
}
.toPageInput input {
  caret-color: white;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--light);
}
.pastReferral {
  overflow: visible;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.totalRewardsColumn {
  text-align: start;
  margin-left: 25px;
}

.userName {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.userName img {
  border-radius: 50%;
}
