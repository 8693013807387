.section {
  border: none;
  background: none;
  padding: 0;
  overflow: unset;
}

.sectionFlex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.memberRankWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.memberRankInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.memberRankInfo h5 {
  color: var(--text-selected);
  margin-bottom: 5px;
}

.memberRankInfo span {
  color: var(--text-placeholder);
  font-size: 12px;
  font-weight: 400;
}

.nextRankInfo {
  margin-top: 30px;
}

.nextRankInfo span {
  color: #fab348;
  font-size: 16px;
  font-weight: 600;
}

.nextRankParagraph {
  font-size: 12px;
}

.memberRankChart {
  position: relative;
  margin-top: -100px;
}

.separator {
  background-color: var(--card-border);
  width: 100%;
  margin: auto;
  height: 1px;
  margin-block: 32px;
}

.rankRulesTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 30px;
}

.rankRulesTitle h6 {
  font-weight: 500;
}

.buttonReadMore {
  border-radius: 26px;
  background: rgba(138, 163, 158, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  font-size: 14px;
  cursor: pointer;
}

.unOrdered {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
}

.unOrdered li {
  max-width: 380px;
  padding-left: 10px;
  font-size: 14px;
}

.maxRankLimit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
}
.pointsHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.pointLine {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: visible;
}

.separatorWithMargin {
  background-color: var(--card-border);
  width: 100%;
  height: 1px;
  margin-block: 16px 25px;
}
.smallCard {
  overflow: visible;
}

.pointInfoLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.extraInfo {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 25px;
  overflow: visible;
  border: 0;
  border-radius: 0;
  background-blend-mode: normal;
  padding: 0;
  background: none;
}
.extraInfo > div {
  flex: 1 !important;
}

@media screen and (max-width: 1199px) {
  .sectionFlex {
    grid-template-columns: repeat(1, 1fr);
  }
  .tooltip {
    max-height: 80px;
  }
  .memberRankChart {
    margin-top: -50px;
  }
  .cardClassName h5 {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .memberRankWrapper {
    margin-bottom: 30px;
    flex-direction: column-reverse;
  }
  .memberRankChart {
    margin-top: 0;
  }
  .memberRankInfo {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .memberRankInfo {
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .rankRulesTitle {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .cardsInfoGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .selector {
    margin: auto;
  }
  .pointsHeading,
  .extraInfo {
    flex-direction: column;
    gap: 20px;
  }
}
