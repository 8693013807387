
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 25px;
}


.titleCard {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--card-border);
  margin-block: 25px;
}

.sliderWrapper {
  margin-block: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.swiperContainer {
  transition: all ease 0.5s;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
}

.next svg {
  transform: rotate(90deg);
}

.prev svg {
  transform: rotate(270deg);
}

.prev,
.next {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  margin-left: 1px;
  border: 1px solid var(--list-line);
}

.prev:hover,
.next:hover {
  background-color: #57575777;
  cursor: pointer;
}

.prev:hover svg,
.next:hover svg {
  color: var(--text-selected);
}

.card,
.cardIsActive {
  background-repeat: no-repeat;
  background-position: center;
  transition: all ease 0.5s;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .imgWrapper,
.cardActive .imgWrapper {
  border-radius: 50%;
  transition: all ease 0.5s;
}

.cardActive .imgWrapper {
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(213.93deg, #35d7bb 7.64%, #354da1 93.97%);
}

.card .imgWrapper {
  background: transparent;
}

.cardActive img,
.card img {
  -webkit-user-drag: none;
  border-radius: 50%;
}

.cardActive img {
  width: 62px;
  height: 62px;
  transition: all ease 0.5s;
}

.card img {
  transition: all ease 0.5s;
  width: 48px;
  height: 48px;
  filter: grayscale(100%);
}

.cardActive a {
  font-size: 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all ease 0.3s;
}

.cardActive a:hover {
  color: var(--text-selected);
}

.cardActive a:hover svg path {
  stroke: var(--text-selected);
}

.card a {
  display: none;
}

.username {
  max-width: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cardActive a svg path,
.card a svg path {
  stroke: white;
  transition: all ease 0.3s;
}

.cardActive a svg {
  width: 13px;
  height: 13px;
  flex-shrink: 0;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.bottomCard {
  border: unset;
  padding: unset;
}

@media screen and (max-width: 768px) {
  .pagination {
    gap: 20px;
    justify-content: flex-end;
  }
  .swiperContainer {
    margin-bottom: 15px;
  }

  .sliderWrapper {
    display: block;
  }
}
