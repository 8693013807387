.wrapper {
  display: grid;
  grid-template-columns: 1fr 262px;
}

.imgWrapper {
  background: url("../../../../../assets/images/vipSupportImage.png") center /
    cover no-repeat;
  max-width: 261px;
  height: 261px;
}

.card,
.formSubmited {
  max-width: 905px;
}

.formSubmited {
  display: grid;
  grid-template-columns: 33px 1fr;
  gap: 15px;
}

.formSubmited img {
  width: 32px;
  height: 32px;
}

.textWrapper > p {
  margin-block: 16px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.formSubmited p {
  margin-bottom: unset;
}

.formInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin-block: 30px 60px;
}

.country,
.language {
  margin-bottom: 20px;
}

.country .label,
.language .label {
  color: var(--text-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.telegramInput {
  position: relative;
}

.telegramInput input {
  padding-left: 40px;
}

.icon {
  border-radius: 4px;
  background: var(--bg-select);
  position: absolute;
  left: 6px;
  top: 40px;
  padding: 4px;
}

.submitButton {
  padding-inline: 50px;
}

.select,
.languageSelect {
  border-radius: 8px;
  border: 1px solid var(--card-border);
  padding: 15px 16px;
  margin-top: 10px;
}

.search {
  background: transparent;
  border: none;
  outline: none;
  color: var(--text-placeholder);
  font-size: 16px;
  font-weight: 500;
}

.placeholder {
  color: var(--text-placeholder);
}

.option {
  display: flex;
  align-items: center;
  color: var(--light);

  font-size: 16px;
  font-weight: 500;
}

.flag {
  font-size: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.label {
  color: var(--text-primary);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

@media screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .imgWrapper {
    order: -1;
    width: 261px;
    margin-inline: auto;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .formInputs {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .imgWrapper {
    width: 100%;
  }
}
