.backgroundDoughnut {
  position: relative;
  z-index: 2;
  width: 244px;
  height: 244px;
  z-index: 1;
}
#my-doughnut-chart-1 canvas {
  z-index: 9999;
}
.backgroundDoughnut span {
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--text-selected);
  font-weight: 700;
}
.firstDoughnut {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 244px;
  height: 244px;
  z-index: 1;
}
.labelStyle {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: 400;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.startFrom,
.endWith {
  position: absolute;
  color: var(--text-placeholder);
  top: 82%;
  font-size: 13px;
}
.startFrom {
  left: 2px;
}
.endWith {
  left: 90%;
}

@media screen and (max-width: 575px) {
  .labelStyle {
    font-size: 12px;
    text-align: center;
  }
}
