.flowLayout {
  height: 1200px;
}
.miniMapCLassName {
  background-color: var(--card-border);
}
.customControls {
  background: black !important;
  color: black !important;
  stroke: black !important;
}
