.titleReport {
  margin-bottom: 35px;
}
.sectionGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-block: 20px;
}
.currentRank,
.monthlyRank {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.currentRankCard,
.monthlyRankCard {
  /* height: 100%; */
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.currentRankCard {
  background: linear-gradient(
    97.75deg,
    #000000 -19.81%,
    #065b4a 31.13%,
    #0deabd 64.14%,
    #0b0b0b 109.08%
  );
}
.monthlyRankCard {
  background-color: var(--bg-select);
}
.rankInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.superSmallTitle {
  font-size: 13px;
  font-weight: 500;
}
.rankDate {
  background: rgba(255, 255, 255, 0.15);
  padding: 8px;
  text-align: center;
  border-radius: 50px;
  margin-top: 20px;
  font-size: 12px;
  width: max-content;
}
.rankImage {
  width: 120px;
  height: 120px;
}
.nextRankCard {
  background-color: var(--bg-select);
  display: flex;
  justify-content: space-between;
}

.memberRankChart {
  position: relative;
  margin-top: -40px;
  transform: translateX(-10px);
}
.rightPart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: url(../../../../../assets/svg/whole-wave.svg) center / contain
    no-repeat;
  border: 0.5px solid var(--card-border);
}
.rightPart h6 {
  font-weight: 600;
  margin-bottom: 20px;
}
.usersCardGrid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  justify-items: flex-start;
  gap: 25px;
}
.singleUsersCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.singleUsersCard P {
  color: var(--text-primary);
  font-size: 14px;
}

.rankRule {
  font-size: var(--font-size-sm);
  text-decoration: underline;
}
.rankRuleContainer {
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.totalWrapper {
  font-size: var(--font-size-sm);
  font-weight: 400;
}
.usersCount {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  gap: 5px;
  font-size: var(--font-size-md);
  font-weight: 600;
}
.usersCount span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}
.total {
  cursor: pointer;
  margin-bottom: 10px;
  font-size: var(--font-size-md);
  font-weight: 600;
}
.downPercentage {
  color: var(--error);
}
.upPercentage {
  color: var(--text-selected);
}
.zeroPercentage {
  color: var(--warning-dark);
}

.percentage span {
  font-size: 11px;
  font-weight: 500;
  color: var(--text-units);
}

.horizontalSeparator {
  width: 100%;
  height: 1px;
  margin: auto;

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #858585 41.67%,
    rgba(252, 252, 252, 0) 100%
  );
}

.usersCardGridFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
  width: 100%;
}
.bottomSection {
  position: relative;
  z-index: 0;
  overflow: unset;
  background: none;
}

.cardsInfoGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardBox {
  width: 100%;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  position: relative;
  gap: 10px;
}

.titleWrapper h6 {
  font-size: 14px;
  font-weight: 400;
}

.bodyWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.bodyWrapper span {
  font-size: var(--font-size-md);
}

.amount {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: var(--font-size-md);
  font-weight: 600;
}

.amount span {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-units);
}

.pointsUsers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.titleUser {
  margin-bottom: 60px;
}

.users {
  background-color: var(--bg-select);
  display: flex;
  flex-direction: column;
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 40px;
  flex-grow: 1;
}

.userLine {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
}

.valueWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.vValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 24.38px;
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: var(--text-primary);
}

.extension {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1%;
  vertical-align: text-top; /* Vertical trim (cap height) */
}

.points {
  background-color: var(--bg-select);
}

.pointsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.pointLine {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: visible;
}

.pointLine {
  overflow: visible;
}

.pointInfoLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}
.itemBorder {
  background-color: var(--card-border);
  width: 100%;
  margin: auto;
  height: 1px;
  margin-block: 18px;
}
@media screen and (max-width: 1500px) {
  .sectionGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1399px) {
  .cardsInfoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 50px;
  }
  .verticalSeparatorDown {
    display: none;
  }
  .cardBox {
    border: none !important;
  }
  .cardBox {
    justify-content: flex-start;
  }
  .pointsUsers {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 991px) {
  .sectionGrid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .cardsInfoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 575px) {
  .userLine {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .usersCardGrid {
    grid-template-columns: 1fr;
  }
  .memberRankChart {
    transform: none;
  }

  .cardsInfoGrid {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  .verticalSeparator {
    width: 100%;
    height: 1px;
    min-height: auto;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #858585 41.67%,
      rgba(252, 252, 252, 0) 100%
    );
  }
  .nextRankCard,
  .currentRankCard,
  .monthlyRankCard {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
  }
  .rankInfo {
    align-items: center;
  }
  .pointsHeading,
  .extraInfo {
    flex-direction: column;
    gap: 20px;
  }
}
