.BonusesHistoryTitle {
  margin-bottom: 50px;
}
.paginationButton {
  background: transparent;
  color: var(--text-primary);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paginationButton:hover {
  background: transparent;
}

.toPageInput {
  border: 2px solid #5a5a5a;
  padding: 15px;
  border-radius: 8px;
  width: 47px;
}
.toPageInput input {
  caret-color: white;
  padding: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: var(--light);
}
.pastReferral {
  overflow: visible;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.paginationRecords {
  color: var(--text-primary);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.multipleValueCell {
  padding-block: 35px;
}
.valueRow {
  padding: "10px 0";
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

@media screen and (max-width: 319px) {
  .card {
    padding-inline: 15px;
  }
}
