
.progress {
	background-color: #9A9A9A;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 6px;
	width: 240px;
}

.progressDone {
    background: linear-gradient(to left, #BAFF27, #FC4259C9);
    box-shadow: 0px 0px 10px 3px rgba(255, 0, 0, 0.200), 0px 0px 10px 3px rgba(0, 255, 0, 0.200);
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}
.startText, .endText {
    position: absolute;
    bottom: -20px; 
    color: #9A9A9A; 
    font-size: 12px; 
}

.startText {
    left: 0;
}

.endText {
    right: 0;
	display: flex;
    align-items: center;
}



.progressImg {
	width: 16px;
	height: 14px;
	margin-right: 4px;

}