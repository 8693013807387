.cardWrapper,
.circleWrapper {
  position: relative;
  transform: translateY(20px);
}

.cardBody,
.circleCardBody,
.cardBodyLoadMore {
  width: 200px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(110deg, rgb(91, 87, 87) 0%, #5d5b5b 100%),
    rgba(64, 64, 64, 0.36);
  background-blend-mode: overlay;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.cardBodyLoadMore {
  background-image: repeating-linear-gradient(
      0deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      90deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      180deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    ),
    repeating-linear-gradient(
      270deg,
      #848484,
      #848484 19px,
      transparent 19px,
      transparent 23px,
      #848484 23px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  border-radius: 0;
  cursor: pointer;
}

.circleCardBody {
  height: 40px;
  background: transparent;
}

.circleCenter {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--light);
  z-index: 2;
  cursor: pointer;
}

.hoverContent {
  visibility: hidden;
  width: 180px;
  height: 105px;
  position: absolute;
  left: 140px;
  border-radius: 8px;
  background: #474747;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.5s;
  opacity: 0;
}

.hoverContent::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 42px;
  left: -12px;
  background-image: url(../../../../../assets/svg/tooltip-corner.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--light);
  font-size: 12px;
  position: relative;
}

.lineWrapper,
.lineWrapperHidden,
.lineWrapperLoadMore {
  position: absolute;
  background-image: linear-gradient(
    to right,
    #4d4d4dd5 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 20px 2px;
  background-repeat: repeat-x;
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 20px;
}

.lineWrapper {
  right: 60px;
  top: -80px;
}

.lineWrapperHidden {
  right: 100px;
  top: -50px;
}
.lineWrapperLoadMore {
  right: 100px;
  top: -50px;
}

.lineLevel {
  color: var(--bonus-chart);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.lineDetails {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 400;
}

.lineDetails span {
  color: var(--light);
  font-weight: 600;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-inline: auto;
  position: absolute;
  bottom: 115px;
  left: 73px;
  z-index: 10;
}

.usernameStyle {
  font-weight: 600;
}

.rankStyle {
  background: linear-gradient(119deg, #42e8e0 0%, #7ae85c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.separator {
  width: 120px;
  height: 1px;
  background: rgba(108, 108, 108, 0.63);
  margin-block: 2px;
}

.infinityValue {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
}

.infinityValue span {
  color: var(--light);
  font-weight: 600;
}

.handleButton {
  width: 35px;
  height: 35px;
  margin: auto;
  cursor: pointer;
}

.customHandleExpand,
.customHandleCollapsed,
.rotating {
  width: 35px !important;
  height: 35px !important;
  background-color: #404040 !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  bottom: 11px;
  transform: translate(-50%, 0);
  z-index: 5 !important;
  background-image: url(../../../../../assets/svg/expandIcon.svg) !important;
}

.customHandleCollapsed {
  background-image: url(../../../../../assets/svg/collapseIcon.svg) !important;
}

.rotating {
  position: relative;
}

.rotating::after {
  animation: rotation 2s infinite linear;
  position: absolute;
  width: 35px;
  height: 35px;
  top: -1px;
  left: -1px;
  content: "";
  border-radius: 50%;
  background-image: url(../../../../../assets/svg/node-loading.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: #9c9c9c;
}
.emptyHandle {
  visibility: hidden !important;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.usernameStyle {
  font-weight: 600;
  color: var(--light);
}
