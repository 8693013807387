.wrapper {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 30px;
}

.balance {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: var(--font-size-lg);
  font-weight: 600;
}

.balance span {
  font-size: var(--font-size-base);
  font-weight: 400;
  color: var(--text-units);
}
