.walletCard {
  background: 
  url("../../../../../../../assets/images/walletsCardTopLeftBG.png")
  no-repeat top left , var(--bg-like-blur);
}

.cardHeader{
  display: flex;
  justify-content: space-between;
}

.action {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.infoBoxAction {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  color: var(--light);
  cursor: pointer;
  font-size: 12px;
}

.infoBoxAction:hover {
  color: var(--text-selected-number);
}

.infoBoxAction svg path {
  transition: all 0.3s ease-in;
}
.infoBoxAction:hover svg path {
  stroke: var(--text-selected-number);
}

.infoBoxActionTitle {
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
  transition: all 0.3s ease-in;
}

.button {
  background: linear-gradient(
    106deg,
    rgba(221, 221, 221, 0.09) 0%,
    rgba(226, 226, 226, 0.02) 100%
  );
  border-radius: 4px;
  padding: 2px 12px;
  font-size: 12px;
  cursor: pointer;
}

.button:hover {
  background: linear-gradient(
    106deg,
    rgba(221, 221, 221, 0.18) 0%,
    rgba(226, 226, 226, 0.04) 100%
  );
}


