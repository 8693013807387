.modalContentWithoutClose {
  padding: 10px 22px 22px;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
}

.modalTitle {
  text-align: center;
  margin-bottom: 38px;
}

.qrRow {
  display: flex;
  justify-content: center;
}

.qrOuterContainer {
  padding: 23px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--card-border);
  margin-bottom: 50px;
  max-width: 289px;
  max-height: 289px;
}

.qrInnerContainer {
  background-color: var(--light);
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 8px;
  padding: 23px 25px;
}

.qrCode {
  width: 100%;
  height: 100%;
}

.social {
  margin-bottom: 30px;
}

.socialTitle {
  color: var(--light);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 26px;
}

.socialIcons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.socialIcons svg {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease-in;
}

.socialIcons svg:hover {
  background: rgba(255, 255, 255, 0.9);
}

.otherOptionsHeading {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}

.otherOptionsTitle {
  flex: 10%;
  text-align: center;
  color: var(--text-placeholder);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.smallDivider {
  flex: 45%;
  height: 1px;
  width: 100%;
  background: var(--card-border);
}

.copySection {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 14px;
}

.copySection .left {
  flex: 55%;
}

.copySection .right {
  flex: 40%;
}

.modalAction,
.modalActionCopy {
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 100%;
  padding: 14px;
  position: relative;

  color: var(--light);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  background-color: rgba(83, 82, 82, 0.26);
  border-radius: 4px;
}

.modalActionCopy {
  background-color: #00a88b;
}

.modalActionCopy svg path {
  stroke: var(--light);
}

.copyTooltip {
  position: absolute;

  top: -70%;
  left: 25%;

  background-color: var(--dark);
  border-radius: 8px;
  padding: 5px 10px;
  color: var(--light);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.copyTooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--dark) transparent transparent transparent;
}

.show {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 575px) {
  .socialIcons {
    gap: 15px;
  }
  .copySection {
    flex-wrap: wrap;
  }
  .copySection .right,
  .copySection .left {
    flex: 100%;
  }
  .copyTooltip {
    left: 40%;
  }
}
