.header {
  margin-block: 32px 44px;
}

.header p {
  font-size: 14px;
  margin-bottom: 7px;
  color: var(--text-primary);
  max-width: 700px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 262px;
}

.textWrapper p:nth-of-type(1) {
  margin-block: 16px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textWrapper p:nth-of-type(2) {
  color: var(--text-placeholder);
  font-weight: 600;
  margin-top: 30px;
}

.textWrapper p:nth-of-type(2) span {
  color: #00a88b;
  cursor: pointer;
}

.sendButton span {
  margin-left: 15px;
}

.sendButton svg {
  flex-shrink: 0;
}

.imgWrapper {
  background: url("../../../../../assets/images/vipSupportImage.png") center /
    cover no-repeat;
  max-width: 261px;
  height: 261px;
}

@media screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
  .textWrapper {
    order: 2;
    text-align: center;
  }

  .sendButton span {
    text-align: left;
  }
}

@media screen and (max-width: 350px) {
  .card {
    padding-inline: 15px;
  }
}
