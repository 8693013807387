.headerWrapper {
  margin-block: 32px 44px;
}

.headerWrapper,
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.wikiBtn {
  width: 198px;
}

.status {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary);
}

.status span {
  font-size: 15px;
  font-weight: 600;
}

.success {
  color: var(--success);
}

.error {
  color: var(--warning);
}

@media screen and (max-width: 767px) {
  .headerWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .wiki {
    margin: auto;
  }
}

@media screen and (max-width: 575px) {
  .titleWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .status {
    margin-left: unset;
  }
}
