.shareInvitation {
  position: relative;
}
.shareInvitation::after {
  content: url("../../../../../assets/images/Share.png");
  position: absolute;
  top: 0;
  right: 0;
}

.shareInvitationTitleContainer {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  margin-bottom: 18px;
}

.shareInvitationTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.shareInvitationSubTitle {
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 773px;
}

.up {
  margin-bottom: 32px;
}

.down {
  display: grid;
  grid-template-columns: 1.7fr 1.7fr 1fr;
  column-gap: 16px;
  align-items: flex-end;
}

.shareActions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 18px;
}

.shareBtn {
  display: inline-flex;
  height: 60px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 8px;
  background-color: #00a88b;
  border: 1px solid #00a88b;
  color: var(--light);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease-in;
}

.shareBtn svg path {
  transition: all 0.3s ease-in;
  stroke: var(--light);
}

.shareBtn:hover svg path {
  stroke: #00a88b;
}

.shareBtn:hover {
  color: #00a88b;
  background-color: transparent;
}

@media screen and (max-width: 1399px) {
  .shareInvitation {
    gap: 20px;
  }
}

@media screen and (max-width: 991px) {
  .shareInvitation {
    flex-direction: column;
  }

  .shareInvitationTitle {
    font-size: 30px;
    text-align: center;
    line-height: normal;
  }

  .shareInvitationTitleContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .shareInvitationSubTitle {
    text-align: center;
    max-width: 100%;
  }

  .down {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    align-items: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .shareActions {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 575px) {
  .shareInvitation {
    padding: 45px 25px;
  }
}
